define("congress-ap-client/components/models-table/theme/summary", ["exports", "ember-models-table/components/models-table/summary"], function (_exports, _summary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div>{{this.summary}}</div>
  */
  {
    "id": "FFTQ4Wcb",
    "block": "[[[10,0],[12],[1,[30,0,[\"summary\"]]],[13]],[],false,[]]",
    "moduleName": "congress-ap-client/components/models-table/theme/summary.hbs",
    "isStrictMode": false
  });

  class ModelsTableThemeSummaryComponent extends _summary.default {}

  _exports.default = ModelsTableThemeSummaryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModelsTableThemeSummaryComponent);
});