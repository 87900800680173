define("congress-ap-client/initializers/emt-themes", ["exports", "congress-ap-client/theme/custom"], function (_exports, _custom) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(application) {
    application.register('emt-theme:custom-theme', _custom.default, {
      singleton: false
    });
  }

  var _default = {
    name: 'emt-themes',
    initialize
  };
  _exports.default = _default;
});