define("congress-ap-client/components/annotation-table/status-col", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="status-col--{{if (eq @record.status 'Success') 'success' (if (eq @record.status 'Failure') 'failure' 'inProgress')}}">{{@record.status}}</div>
  */
  {
    "id": "JyAnApYF",
    "block": "[[[10,0],[15,0,[29,[\"status-col--\",[52,[28,[37,1],[[30,1,[\"status\"]],\"Success\"],null],\"success\",[52,[28,[37,1],[[30,1,[\"status\"]],\"Failure\"],null],\"failure\",\"inProgress\"]]]]],[12],[1,[30,1,[\"status\"]]],[13]],[\"@record\"],false,[\"if\",\"eq\"]]",
    "moduleName": "congress-ap-client/components/annotation-table/status-col.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});