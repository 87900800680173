define("congress-ap-client/mirage/factories/team", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return `${_faker.default.lorem.word()}`;
    },

    usersCount: Math.floor(Math.random() * 5) + 1,
    plannersCount: Math.floor(Math.random() * 5) + 1,
    withTenant: (0, _emberCliMirage.trait)({
      afterCreate(team, server) {
        server.create('tenant', {
          teams: [team]
        });
      }

    }),
    // withPlanners: trait({
    //     afterCreate(team, server) {
    //         server.createList('planner', 5, { team });
    //     },
    // }),
    withTenantUsers: (0, _emberCliMirage.trait)({
      afterCreate(team, server) {
        server.createList('tenant-user', 10, {
          teams: [team]
        });
      }

    })
  });

  _exports.default = _default;
});