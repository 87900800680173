define("congress-ap-client/mirage/factories/congress-audit", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    id() {
      return _faker.default.datatype.uuid();
    },

    timestamp() {
      return _faker.default.date.soon();
    },

    user(i) {
      let first_name = _faker.default.name.firstName();

      let last_name = _faker.default.name.lastName();

      return {
        emailId: `${first_name.toLowerCase()}.${last_name.toLowerCase()}@zoomrx.com`,
        firstName: first_name,
        lastName: last_name,
        id: `Z00${i}`
      };
    },

    transaction(i) {
      return {
        operationName: _faker.default.lorem.words(),
        details: {
          detail_key: _faker.default.lorem.sentence()
        },
        outcome: i % 2 ? 'SUCCESS' : 'FAILURE'
      };
    }

  });

  _exports.default = _default;
});