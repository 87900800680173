define("congress-ap-client/utils/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isEmpty;

  function isEmpty(value) {
    try {
      if (value === undefined || value === null) {
        return true;
      }

      if (value.length && value.length === 0) {
        return true;
      }

      if (typeof value === 'string' && value.trim() === '') {
        return true;
      }

      if (typeof value === 'object' && value instanceof Date) {
        return false;
      }

      if (typeof value === 'object' && Object.keys(value).length === 0) {
        return true;
      }

      return false;
    } catch {
      return false;
    }
  }
});