define("congress-ap-client/routes/congress-view/congress-details", ["exports", "congress-ap-client/config/environment", "congress-ap-client/utils/http-url-to-web-socket-url", "congress-ap-client/utils/custom-event", "congress-ap-client/utils/socket"], function (_exports, _environment, _httpUrlToWebSocketUrl, _customEvent, _socket) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const SOCKET_URL = `${(0, _httpUrlToWebSocketUrl.default)(_environment.default.APP.host || window.location.origin + '/')}`;
  let CongressViewCongressDetailsRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember.inject.service, _dec8 = Ember.inject.service, (_class = class CongressViewCongressDetailsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);

      _initializerDefineProperty(this, "toast", _descriptor3, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor4, this);

      _initializerDefineProperty(this, "congressCategory", _descriptor5, this);

      _initializerDefineProperty(this, "congressStatus", _descriptor6, this);

      _initializerDefineProperty(this, "timeZone", _descriptor7, this);

      _initializerDefineProperty(this, "router", _descriptor8, this);

      _defineProperty(this, "breadCrumb", {
        title: null
      });
    }

    get token() {
      return this.session.data.authenticated.token;
    }

    async model(params) {
      try {
        const [,,, congressDetails] = await Promise.all([this.congressCategory.fetchCategories(), this.congressStatus.fetchStatuses(), this.timeZone.fetchTimeZones(), this.store.findRecord('congress', params.congress_id, {
          include: 'planners'
        })]);
        return congressDetails;
      } catch (error) {
        this.errorHandler.notifyError(error);
        this.router.transitionTo('congress-view');
      }
    }

    afterModel(model) {
      this.breadCrumb.title = (model === null || model === void 0 ? void 0 : model.name) || '';
    } // WebSocket initialization


    setupController(controller, model) {
      super.setupController(controller, model);

      try {
        const websocket = new _socket.socket(`${SOCKET_URL}ws?congress_id=${model.id}&token=${this.token}`, {
          onmessage: event => {
            controller.socketListener && controller.websocket && controller.socketListener.trigger('onSocketMessage', controller.websocket.getData(event));
          },
          onmaximum: event => {
            this.toast.error('Error in connecting to the websocket server');
          }
        });
        controller.set('socketListener', new _customEvent.default());
        controller.set('websocket', websocket);
        controller.updateDates();
      } catch (error) {
        this.toast.error('Error in connecting to the websocket server');
      }
    } // WebSocket teardown


    resetController(controller) {
      controller.websocket.close(_socket.ERROR_CODE.NORMAL_CLOSURE);
      controller.set('socketListener', null);
      controller.set('websocket', null);
      controller.set('plannerExpandedView', false);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "congressCategory", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "congressStatus", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "timeZone", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = CongressViewCongressDetailsRoute;
});