define("congress-ap-client/instance-initializers/emt-inject", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.inject('component:models-table', 'themeInstance', 'emt-theme:custom-theme');
    appInstance.inject('component:models-table-server-paginated', 'themeInstance', 'emt-theme:custom-theme');
  }

  var _default = {
    name: 'emt-inject',
    initialize
  };
  _exports.default = _default;
});