define("congress-ap-client/components/tenant/tenant-list/action-cell", ["exports", "@glimmer/component", "congress-ap-client/utils/prepend-url-scheme"], function (_exports, _component, _prependUrlScheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='tenant-actions'>
      <LinkTo
          @route='tenants.tenant'
          @model={{@record.id}}
          @query={{hash teamId=@record.teams.firstObject.id}}>
          <Button class='primary'><img src='/images/redirect.svg' /> Open Tenant </Button>
      </LinkTo>
      <Button
          class='primary view-website'
          {{on 'click' (fn this.viewWebsite @record.website)}}>
          <img src='/images/globe-white.svg' /> View Portal </Button>
  </div>
  */
  {
    "id": "SSy4uNL7",
    "block": "[[[10,0],[14,0,\"tenant-actions\"],[12],[1,\"\\n    \"],[8,[39,0],null,[[\"@route\",\"@model\",\"@query\"],[\"tenants.tenant\",[30,1,[\"id\"]],[28,[37,1],null,[[\"teamId\"],[[30,1,[\"teams\",\"firstObject\",\"id\"]]]]]]],[[\"default\"],[[[[1,\"\\n        \"],[8,[39,2],[[24,0,\"primary\"]],null,[[\"default\"],[[[[10,\"img\"],[14,\"src\",\"/images/redirect.svg\"],[12],[13],[1,\" Open Tenant \"]],[]]]]],[1,\"\\n    \"]],[]]]]],[1,\"\\n    \"],[8,[39,2],[[24,0,\"primary view-website\"],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"viewWebsite\"]],[30,1,[\"website\"]]],null]],null]],null,[[\"default\"],[[[[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/globe-white.svg\"],[12],[13],[1,\" View Portal \"]],[]]]]],[1,\"\\n\"],[13]],[\"@record\"],false,[\"link-to\",\"hash\",\"button\",\"on\",\"fn\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-list/action-cell.hbs",
    "isStrictMode": false
  });

  let TenantTenantListActionCellComponent = (_dec = Ember._action, (_class = class TenantTenantListActionCellComponent extends _component.default {
    viewWebsite(url) {
      event.stopPropagation();
      window.open((0, _prependUrlScheme.default)(url), '_blank');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "viewWebsite", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "viewWebsite"), _class.prototype)), _class));
  _exports.default = TenantTenantListActionCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantListActionCellComponent);
});