define("congress-ap-client/services/toast", ["exports", "sweetalert2", "congress-ap-client/utils/is-empty"], function (_exports, _sweetalert, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  /**
   * Arguments:
   *  title - Message to display in the toast
   *  options - Refer https://sweetalert2.github.io/#configuration
   *
   * Methods:
   *  success(title)
   *  info(title)
   *  warning(title)
   *  error(title)
   */
  class ToastService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "defaultOptions", {
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        showCloseButton: false,
        timer: 4000
      });

      _defineProperty(this, "defaultClass", {
        showClass: {
          popup: 'animate-back-in-down'
        },
        hideClass: {
          popup: 'animate-back-out-up'
        },
        customClass: {
          container: 'toast-default',
          popup: 'popup',
          title: 'title',
          icon: 'icon'
        }
      });

      _defineProperty(this, "closeButton", {
        success: '<img src="/images/times-circle-success.svg">',
        info: '',
        // TODO: Get icon from design team
        warning: '',
        //TODO: Get icon from design team
        error: '<img src="/images/times-circle-failure.svg">'
      });

      _defineProperty(this, "success", this.generateAlert('success'));

      _defineProperty(this, "info", this.generateAlert('info'));

      _defineProperty(this, "warning", this.generateAlert('warning'));

      _defineProperty(this, "error", this.generateAlert('error'));
    }

    generateAlert(type) {
      return function (title, options = {}) {
        if ((0, _isEmpty.default)(title)) {
          (true && !(false) && Ember.assert('Toast should be called with title', false));
          return;
        }

        _sweetalert.default.fire({ ...this.defaultOptions,
          closeButtonHtml: this.closeButton[type],
          title: title,
          ...options,
          showClass: { ...this.defaultClass.showClass,
            ...options.showClass
          },
          hideClass: { ...this.defaultClass.hideClass,
            ...options.hideClass
          },
          customClass: { ...this.defaultClass.customClass,
            container: `toast-default toast-${type}`,
            ...options.customClass
          }
        });
      };
    }

  }

  _exports.default = ToastService;
});