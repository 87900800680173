define("congress-ap-client/components/sidebar-table/sidebar-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
  {{on 'click' (fn @actions.toggleSidebarFooter @id)}}
  class="footer-item{{if (eq @state.selectedFooter @id) '--selected'}}">
    <span>{{@title}}</span>
  </div>
  
  */
  {
    "id": "xHMP7BOY",
    "block": "[[[11,0],[16,0,[29,[\"footer-item\",[52,[28,[37,1],[[30,1,[\"selectedFooter\"]],[30,2]],null],\"--selected\"]]]],[4,[38,2],[\"click\",[28,[37,3],[[30,3,[\"toggleSidebarFooter\"]],[30,2]],null]],null],[12],[1,\"\\n  \"],[10,1],[12],[1,[30,4]],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@state\",\"@id\",\"@actions\",\"@title\"],false,[\"if\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "congress-ap-client/components/sidebar-table/sidebar-footer.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});