define("congress-ap-client/components/legend", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='legend' ...attributes>
      <div class='legend-data'>{{yield to='data'}}</div>
      <div class='legend-label'>{{yield to='label'}}</div>
  </div>
  */
  {
    "id": "NNlrD0Df",
    "block": "[[[11,0],[24,0,\"legend\"],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"legend-data\"],[12],[18,2,null],[13],[1,\"\\n    \"],[10,0],[14,0,\"legend-label\"],[12],[18,3,null],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"&data\",\"&label\"],false,[\"yield\"]]",
    "moduleName": "congress-ap-client/components/legend.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});