define("congress-ap-client/components/ember-inline-edit", ["exports", "ember-inline-edit/components/ember-inline-edit"], function (_exports, _emberInlineEdit) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberInlineEdit.default.extend({
    saveOnFocusOut: true,

    _handleClicks(ev) {
      if (!this.enabled) return;
      let {
        isEditing
      } = this;
      let clickedInside = this.element.contains(ev.target);

      if (clickedInside && !isEditing) {
        if (this.showEditButton) {
          return;
        }

        this._setFieldWidth();

        this.send('startEditing', ev);
      } else if (!clickedInside && isEditing) {
        if (this.onOutsideClick) {
          this.onOutsideClick(this.value) && set(this, 'isEditing', false);
        } else {
          if (this.saveOnFocusOut) {
            this.send('save');
          } else {
            this.send('cancel');
          }
        }
      }
    }

  });

  _exports.default = _default;
});