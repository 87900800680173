define("congress-ap-client/components/congress-audit-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.auditTableLoading}}
  	<TableLoader @loading={{this.auditTableLoading}}/>
  {{/if}}
  	<div class='session-footer-content'>
  		{{#if this.isPageLoading}}
  			<TableLoader @loading={{this.isPageLoading}} />
  		{{/if}}
  		{{#if this.data.length}}
  			<ModelsTableServerPaginated
  				@tableId='congress-audit'
  				@data={{this.data}}
  				@columns={{this.columns}}
  				@expandedRowComponent={{component 'congress-audit-table/log-details' congress=@congress}}
  				@multipleColumnsSorting={{false}}
  				@multipleExpand={{true}}
  				@useFilteringByColumns={{false}}
  				@useSrtingByColumns={{false}}
  				@showColumnsDropdown={{false}}
  				@showGlobalFilter={{false}}
  				@showPageSize={{false}}
  				@useNumericPagination={{true}}
  				@metaItemsCountProperty='total'
  				@metaPagesCountProperty='pagesCount'
  				@pageSize={{this.pageSize}}
  				@selectRowOnClick={{true}}
  				@showTableSpinner={{this.showTableSpinner}}
  			/>
  		{{else}}
  			<div class="no-data-placeholder">
  				No audit logs
  			</div>
  		{{/if}}
  	</div>
  */
  {
    "id": "wSSXE7P4",
    "block": "[[[41,[30,0,[\"auditTableLoading\"]],[[[1,\"\\t\"],[8,[39,1],null,[[\"@loading\"],[[30,0,[\"auditTableLoading\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"\\t\"],[10,0],[14,0,\"session-footer-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"isPageLoading\"]],[[[1,\"\\t\\t\\t\"],[8,[39,1],null,[[\"@loading\"],[[30,0,[\"isPageLoading\"]]]],null],[1,\"\\n\"]],[]],null],[41,[30,0,[\"data\",\"length\"]],[[[1,\"\\t\\t\\t\"],[8,[39,2],null,[[\"@tableId\",\"@data\",\"@columns\",\"@expandedRowComponent\",\"@multipleColumnsSorting\",\"@multipleExpand\",\"@useFilteringByColumns\",\"@useSrtingByColumns\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@showPageSize\",\"@useNumericPagination\",\"@metaItemsCountProperty\",\"@metaPagesCountProperty\",\"@pageSize\",\"@selectRowOnClick\",\"@showTableSpinner\"],[\"congress-audit\",[30,0,[\"data\"]],[30,0,[\"columns\"]],[50,\"congress-audit-table/log-details\",0,null,[[\"congress\"],[[30,1]]]],false,true,false,false,false,false,false,true,\"total\",\"pagesCount\",[30,0,[\"pageSize\"]],true,[30,0,[\"showTableSpinner\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\"],[10,0],[14,0,\"no-data-placeholder\"],[12],[1,\"\\n\\t\\t\\t\\tNo audit logs\\n\\t\\t\\t\"],[13],[1,\"\\n\"]],[]]],[1,\"\\t\"],[13]],[\"@congress\"],false,[\"if\",\"table-loader\",\"models-table-server-paginated\",\"component\"]]",
    "moduleName": "congress-ap-client/components/congress-audit-table.hbs",
    "isStrictMode": false
  });

  let CongressAuditTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._action, _dec7 = Ember._action, (_class = class CongressAuditTableComponent extends _component.default {
    showTableSpinner(state) {
      this.isPageLoading = state;
    }

    async fetchAuditData() {
      this.auditTableLoading = true;

      try {
        this.data = await this.store.query('congress-audit', {
          congress: this.args.congress,
          months: 3,
          reqType: 'POST',
          limit: this.pageSize,
          offset: 0
        });
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.auditTableLoading = false;
      }
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor2, this);

      _initializerDefineProperty(this, "data", _descriptor3, this);

      _initializerDefineProperty(this, "auditTableLoading", _descriptor4, this);

      _initializerDefineProperty(this, "isPageLoading", _descriptor5, this);

      _defineProperty(this, "pageSize", 5);

      _defineProperty(this, "columns", [{
        propertyName: 'user.emailId',
        title: 'Email',
        disableSorting: true,
        className: 'footer-table-columns email-col'
      }, {
        propertyName: 'formattedTimestamp',
        title: 'Ended By',
        disableSorting: true,
        className: 'footer-table-columns ended-by-col'
      }, {
        propertyName: 'transaction.operationName',
        title: 'Activity Performed',
        disableSorting: true,
        className: 'footer-table-columns operation-name-col'
      }, {
        title: 'Status',
        component: 'congress-audit-table/status-col',
        disableSorting: true,
        className: 'footer-table-columns status-col'
      }, {
        component: 'congress-audit-table/get-details',
        disableSorting: true,
        className: 'footer-table-columns get-details-col'
      }]);

      this.fetchAuditData();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "auditTableLoading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isPageLoading", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "showTableSpinner", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "showTableSpinner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchAuditData", [_dec7], Object.getOwnPropertyDescriptor(_class.prototype, "fetchAuditData"), _class.prototype)), _class));
  _exports.default = CongressAuditTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CongressAuditTableComponent);
});