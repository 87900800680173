define("congress-ap-client/components/sessions-data-table/session-basic-operations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='session-data-table-submenu'>
      <div class='submenu-label'>Download</div>
      <div
          class='submenu-action {{if (not @actions.canDownload) 'disable'}}'
          onClick={{if @actions.canDownload (perform @actions.download 'sessions/download')}}
      >
          All Sessions
      </div>
      <div
          class='submenu-action {{if (not @actions.canDownload) 'disable'}}'
          onClick={{if @actions.canDownload (fn @actions.downloadSampleSessions)}}
      >
          Sample CSV
      </div>
      <div class='submenu-divider'></div>
  </div>
  <div class='session-data-table-submenu-divider'></div>
  <div class='session-data-table-submenu'>
      <div class='submenu-label'>Upload</div>
  
          <div class="add-session">
              <SessionsDataTable::AppendSession
                  @upload={{@actions.add}}
                  @route='sessions'
                  @disabled={{if (not @actions.canEdit) true}}
                  class='{{if (not @actions.canEdit) 'disable'}}'
              />
          </div>
          <div class="modify-session">
              <SessionsDataTable::FileUpload
                  @upload={{@actions.upload}}
                  @route='sessions'
                  @disabled={{if (not @actions.canEdit) true}}
                  class='{{if (not @actions.canEdit) 'disable'}}'
              />
          </div>
  </div>
  */
  {
    "id": "4D6Ep6sl",
    "block": "[[[10,0],[14,0,\"session-data-table-submenu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"submenu-label\"],[12],[1,\"Download\"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"submenu-action \",[52,[28,[37,1],[[30,1,[\"canDownload\"]]],null],\"disable\"]]]],[15,\"onClick\",[52,[30,1,[\"canDownload\"]],[28,[37,2],[[30,1,[\"download\"]],\"sessions/download\"],null]]],[12],[1,\"\\n        All Sessions\\n    \"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"submenu-action \",[52,[28,[37,1],[[30,1,[\"canDownload\"]]],null],\"disable\"]]]],[15,\"onClick\",[52,[30,1,[\"canDownload\"]],[28,[37,3],[[30,1,[\"downloadSampleSessions\"]]],null]]],[12],[1,\"\\n        Sample CSV\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"submenu-divider\"],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"session-data-table-submenu-divider\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"session-data-table-submenu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"submenu-label\"],[12],[1,\"Upload\"],[13],[1,\"\\n\\n        \"],[10,0],[14,0,\"add-session\"],[12],[1,\"\\n            \"],[8,[39,4],[[16,0,[29,[[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],\"disable\"]]]]],[[\"@upload\",\"@route\",\"@disabled\"],[[30,1,[\"add\"]],\"sessions\",[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],true]]],null],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"modify-session\"],[12],[1,\"\\n            \"],[8,[39,5],[[16,0,[29,[[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],\"disable\"]]]]],[[\"@upload\",\"@route\",\"@disabled\"],[[30,1,[\"upload\"]],\"sessions\",[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],true]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"],[13]],[\"@actions\"],false,[\"if\",\"not\",\"perform\",\"fn\",\"sessions-data-table/append-session\",\"sessions-data-table/file-upload\"]]",
    "moduleName": "congress-ap-client/components/sessions-data-table/session-basic-operations.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});