define("congress-ap-client/controllers/congress-view/congress-details", ["exports", "congress-ap-client/utils/is-empty", "ember-concurrency", "congress-ap-client/utils/download-file-using-blob", "moment-timezone"], function (_exports, _isEmpty, _emberConcurrency, _downloadFileUsingBlob, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const TABLE_IDS = {
    'congresses': 'sessions',
    'planners': 'plannerSessions'
  };
  let CongressViewCongressDetailsController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember.inject.service, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec15 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, (_class = class CongressViewCongressDetailsController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "errorHandler", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "congressStatus", _descriptor3, this);

      _initializerDefineProperty(this, "ajax", _descriptor4, this);

      _initializerDefineProperty(this, "timeZone", _descriptor5, this);

      _initializerDefineProperty(this, "retainFilters", _descriptor6, this);

      _initializerDefineProperty(this, "plannerExpandedView", _descriptor7, this);

      _initializerDefineProperty(this, "startDate", _descriptor8, this);

      _initializerDefineProperty(this, "endDate", _descriptor9, this);

      _initializerDefineProperty(this, "triggerRerender", _descriptor10, this);
    }

    get congressStatuses() {
      return this.congressStatus.statusListInServerOrder;
    }

    get timeZoneList() {
      return this.timeZone.listOfTimeZone;
    }

    updateDates() {
      this.startDate = _momentTimezone.default.utc(this.model.startDate).tz(this.model.timezoneId).format('YYYY-MM-DDTHH:mm:ss');
      this.endDate = _momentTimezone.default.utc(this.model.endDate).tz(this.model.timezoneId).format('YYYY-MM-DDTHH:mm:ss');
    }

    togglePlannerView() {
      this.plannerExpandedView = !this.plannerExpandedView;
    }

    async onUpdate() {
      try {
        let changedAttributes = this.model.changedAttributes();

        if ((0, _isEmpty.default)(changedAttributes) || (0, _isEmpty.default)(this.model[Object.keys(changedAttributes)[0]])) {
          this.model.rollbackAttributes();
          return;
        }

        await this.model.save();
        this.triggerRerender = !this.triggerRerender;
      } catch (error) {
        this.model.rollbackAttributes();
        this.errorHandler.notifyError(error);
      }
    }

    *uploadSynonyms(files) {
      try {
        if (!files.length) {
          return;
        }

        let formData = new FormData();
        formData.append('file', files[0]);
        yield this.ajax.request(`congresses/${this.model.id}/synonyms/upload`, {
          type: 'PUT',
          data: formData,
          processData: false,
          contentType: false
        });
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

    *downloadSynonyms() {
      try {
        let response = yield fetch(`${this.ajax.host}congresses/${this.model.id}/synonyms/download`, {
          headers: this.ajax.headers
        }); // fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500

        if (response.ok) {
          let blob = yield response.blob(); // Retrieve Response Stream as Blob

          let fileName = response.headers.get('content-disposition'); // Get file name from header

          (0, _downloadFileUsingBlob.default)(blob, fileName, 'synonyms.txt');
          return;
        }

        throw {
          errors: [{
            status: response.status,
            title: response.statusText || 'Oops! Something went wrong. Please try again.'
          }]
        };
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

    parseDate(dateString, format) {
      let timezonedDate = _momentTimezone.default.tz(dateString, this.model.timezoneId);

      return new Date(timezonedDate.year(), timezonedDate.month(), timezonedDate.date(), timezonedDate.hour(), timezonedDate.minute(), timezonedDate.second());
    }

    dateChange(attribute, date, dateStr) {
      this[attribute] = dateStr;
      let dateObj = (0, _momentTimezone.default)(dateStr);

      if (attribute === 'startDate') {
        dateObj.set({
          hour: 0,
          minute: 0,
          second: 0
        });
      } else {
        dateObj.set({
          hour: 23,
          minute: 59,
          second: 59
        });
      }

      dateObj = dateObj.format('YYYY-MM-DDTHH:mm:ss');
      this.model[attribute] = _momentTimezone.default.tz(dateObj, this.model.timezoneId).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

    updateTimezone(newTimezoneId) {
      let congressUrl = `/congresses/${this.model.id}:${TABLE_IDS['congresses']}`;
      this.retainFilters.modifyDateFiltersFor(congressUrl, 'startDate', this.model.timezoneId, newTimezoneId);
      this.retainFilters.modifyDateFiltersFor(congressUrl, 'endDate', this.model.timezoneId, newTimezoneId);
      let planners = this.model.planners;
      planners.forEach(planner => {
        let plannerUrl = `/planners/${planner.id}:${TABLE_IDS['planners']}`;
        this.retainFilters.modifyDateFiltersFor(plannerUrl, 'startDate', this.model.timezoneId, newTimezoneId);
        this.retainFilters.modifyDateFiltersFor(plannerUrl, 'endDate', this.model.timezoneId, newTimezoneId);
      });
      this.model.timezoneId = newTimezoneId;
      this.dateChange('startDate', null, this.startDate);
      this.dateChange('endDate', null, this.endDate);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "congressStatus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "timeZone", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "retainFilters", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "plannerExpandedView", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "triggerRerender", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateDates", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "updateDates"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "togglePlannerView", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "togglePlannerView"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onUpdate", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "onUpdate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadSynonyms", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "uploadSynonyms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadSynonyms", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "downloadSynonyms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "parseDate", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "parseDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "dateChange", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "dateChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateTimezone", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "updateTimezone"), _class.prototype)), _class));
  _exports.default = CongressViewCongressDetailsController;
});