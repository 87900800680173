define("congress-ap-client/components/search-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Dropdown class="zrx-search-dropdown" ...attributes>
      <:title as |dropdown|>
          {{#if (has-block "title")}}
              {{yield (hash
                  expanded=dropdown.expanded
                  toggleContent=dropdown.toggleContent
              ) to="title"}}
          {{/if}}
      </:title>
      <:content as |dropdown|>
          {{#if (has-block "content")}}
              <SearchBox
                  @timeDelay={{1000}}
                  @onSearch={{@onSearch}}
                  @searchPlaceHolder={{if @searchPlaceholder  @searchPlaceholder "Search"}}
              />
              <div class="search-content">
                  {{yield (hash
                      expanded=dropdown.expanded
                      toggleContent=dropdown.toggleContent
                  ) to="content"}}
              </div>
          {{/if}}
      </:content>
  </Dropdown>
  */
  {
    "id": "MHotaySu",
    "block": "[[[8,[39,0],[[24,0,\"zrx-search-dropdown\"],[17,1]],null,[[\"title\",\"content\"],[[[[1,\"\\n\"],[41,[48,[30,6]],[[[1,\"            \"],[18,6,[[28,[37,4],null,[[\"expanded\",\"toggleContent\"],[[30,2,[\"expanded\"]],[30,2,[\"toggleContent\"]]]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"]],[2]],[[[1,\"\\n\"],[41,[48,[30,7]],[[[1,\"            \"],[8,[39,5],null,[[\"@timeDelay\",\"@onSearch\",\"@searchPlaceHolder\"],[1000,[30,4],[52,[30,5],[30,5],\"Search\"]]],null],[1,\"\\n            \"],[10,0],[14,0,\"search-content\"],[12],[1,\"\\n                \"],[18,7,[[28,[37,4],null,[[\"expanded\",\"toggleContent\"],[[30,3,[\"expanded\"]],[30,3,[\"toggleContent\"]]]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"]],[3]]]]]],[\"&attrs\",\"dropdown\",\"dropdown\",\"@onSearch\",\"@searchPlaceholder\",\"&title\",\"&content\"],false,[\"dropdown\",\"if\",\"has-block\",\"yield\",\"hash\",\"search-box\"]]",
    "moduleName": "congress-ap-client/components/search-dropdown.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});