define("congress-ap-client/mirage/factories/planner", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return `Planner for ${this.teamName} @ ${_faker.default.lorem.word()}`;
    },

    tenantName: _faker.default.company.companyName(),

    teamName() {
      return `Team ${_faker.default.lorem.word()}`;
    },

    isPublished: 0,
    calendarEnabled: 0,
    isSynced: true,
    withCongress: (0, _emberCliMirage.trait)({
      afterCreate(planner, server) {
        server.create('congress', {
          planners: [planner]
        });
      }

    }),
    withTenant: (0, _emberCliMirage.trait)({
      afterCreate(planner, server) {
        server.create('tenant', {
          planners: [planner]
        });
      }

    }) // withTeam: trait({
    //     afterCreate(planner, server) {
    //         server.create('team', { planners: [planner] });
    //     },
    // }),

  });

  _exports.default = _default;
});