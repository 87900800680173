define("congress-ap-client/mirage/factories/annotation", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    annotationName(i) {
      return `${i}_Indication`;
    },

    startedOn() {
      return _faker.default.date.recent();
    },

    endedBy() {
      return _faker.default.date.soon();
    },

    status(i) {
      if (i % 2 == 0) {
        return 'Success';
      } else {
        return 'Failure';
      }
    },

    email() {
      return _faker.default.internet.email();
    },

    transactionId() {
      return _faker.default.datatype.uuid();
    },

    outputLocation: 'ferma_congress_management.sessions_annotation',

    failureDetails() {
      if (this.status == 'Success') {
        return [];
      }

      return _faker.default.lorem.sentences(5).split('.');
    }

  });

  _exports.default = _default;
});