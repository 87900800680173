define("congress-ap-client/components/tenant/tenant-list/pin-unpin-tenant-cell", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      class='pin-unpin-cell {{if this.pinUnpinTenant.isRunning 'loading' (if this.isPinned 'pinned' 'un-pinned')}}'
      {{on 'click' (perform this.pinUnpinTenant)}}
  ></div>
  */
  {
    "id": "IuUC9RSX",
    "block": "[[[11,0],[16,0,[29,[\"pin-unpin-cell \",[52,[30,0,[\"pinUnpinTenant\",\"isRunning\"]],\"loading\",[52,[30,0,[\"isPinned\"]],\"pinned\",\"un-pinned\"]]]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"pinUnpinTenant\"]]],null]],null],[12],[13]],[],false,[\"if\",\"on\",\"perform\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-list/pin-unpin-tenant-cell.hbs",
    "isStrictMode": false
  });

  let TenantTenantListPinUnpinTenantCellComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class TenantTenantListPinUnpinTenantCellComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "errorHandler", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "isPinned", _descriptor3, this);
    }

    *pinUnpinTenant() {
      event.stopPropagation();

      try {
        yield this.ajax.request(`tenants/${this.args.record.id}/${this.isPinned ? 'unpin' : 'pin'}`, {
          type: 'PUT'
        });
        this.isPinned = this.isPinned ? 0 : 1;
        this.args.column.fetchTenants.perform();
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isPinned", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.record.isPinned || 0;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "pinUnpinTenant", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "pinUnpinTenant"), _class.prototype)), _class));
  _exports.default = TenantTenantListPinUnpinTenantCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantListPinUnpinTenantCellComponent);
});