define("congress-ap-client/components/tenant/tenant-member-list/teams-cell", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='tenant-user-teams'>
      <div class='short-view'>
          {{this.collapsedView}}
      </div>
      {{#if (gt this.teams.length 2)}}
          <div class='expanded-view'>
              {{#each this.teams as |team|}}
                  <div class='team-item'>{{team}}</div>
              {{/each}}
          </div>
      {{/if}}
  </div>
  */
  {
    "id": "v0qjuEJB",
    "block": "[[[10,0],[14,0,\"tenant-user-teams\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"short-view\"],[12],[1,\"\\n        \"],[1,[30,0,[\"collapsedView\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[28,[37,1],[[30,0,[\"teams\",\"length\"]],2],null],[[[1,\"        \"],[10,0],[14,0,\"expanded-view\"],[12],[1,\"\\n\"],[42,[28,[37,3],[[28,[37,3],[[30,0,[\"teams\"]]],null]],null],null,[[[1,\"                \"],[10,0],[14,0,\"team-item\"],[12],[1,[30,1]],[13],[1,\"\\n\"]],[1]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"team\"],false,[\"if\",\"gt\",\"each\",\"-track-array\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-member-list/teams-cell.hbs",
    "isStrictMode": false
  });

  const MASTER_ADMIN = 2;

  class TenantTenantMemberListTeamsCellComponent extends _component.default {
    get teams() {
      // Hack need to handle properly: For master admin team id is sent as zero
      if (this.args.record.teams.find(team => +team.roleId === MASTER_ADMIN) || +this.args.record.roleId === MASTER_ADMIN) {
        return ['All Teams'];
      } // Hack need to handle properly: if the user has no teams empty object is sent


      if (!this.args.record.teams.length || this.args.record.teams.length === 1 && this.args.record.teams.find(team => +team.id === 0)) {
        return ['No Teams'];
      }

      return this.args.record.teams.map(team => team.name);
    }

    get collapsedView() {
      if (this.teams.length <= 2) {
        return this.teams.join(', ');
      }

      let team = this.teams.slice(0, 2).join(', ');
      return Ember.String.htmlSafe(`${team} <span class='other-teams'>+ ${this.teams.length - 2} others</span>`);
    }

  }

  _exports.default = TenantTenantMemberListTeamsCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantMemberListTeamsCellComponent);
});