define("congress-ap-client/components/tenant/team-list/member-list/action-cell", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (not this.isTenantAdmin)}}
      <div class='delete-user' {{on 'click' (toggle 'showConfirmationDialog' this)}}>
          <img src='/images/delete.svg' />
      </div>
      {{#if this.showConfirmationDialog}}
      {{#modal-dialog
          containerClass='unassign-team-user-confirmation-dialog'
          translucentOverlay=true
          onClose=(toggle 'showConfirmationDialog' this)
      }}
          <div class='title'>
              Are you sure you want to unassign <span class='name'>{{@record.name}}</span>?
          </div>
          <div class='actions'>
              <Button
                  class='cautious'
                  @loading={{this.deleteUser.isRunning}}
                  {{on 'click' (pipe (perform this.deleteUser) (toggle 'showConfirmationDialog' this))}}
              >
                  Unassign
              </Button>
              <Button
                  class='secondary'
                  {{on 'click' (toggle 'showConfirmationDialog' this)}}
              >
                  Close
              </Button>
          </div>
      {{/modal-dialog}}
  {{/if}}
  {{/if}}
  */
  {
    "id": "eAcBw9pF",
    "block": "[[[41,[28,[37,1],[[30,0,[\"isTenantAdmin\"]]],null],[[[1,\"    \"],[11,0],[24,0,\"delete-user\"],[4,[38,2],[\"click\",[28,[37,3],[\"showConfirmationDialog\",[30,0]],null]],null],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/delete.svg\"],[12],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"showConfirmationDialog\"]],[[[6,[39,4],null,[[\"containerClass\",\"translucentOverlay\",\"onClose\"],[\"unassign-team-user-confirmation-dialog\",true,[28,[37,3],[\"showConfirmationDialog\",[30,0]],null]]],[[\"default\"],[[[[1,\"        \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n            Are you sure you want to unassign \"],[10,1],[14,0,\"name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"?\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n            \"],[8,[39,5],[[24,0,\"cautious\"],[4,[38,2],[\"click\",[28,[37,6],[[28,[37,7],[[30,0,[\"deleteUser\"]]],null],[28,[37,3],[\"showConfirmationDialog\",[30,0]],null]],null]],null]],[[\"@loading\"],[[30,0,[\"deleteUser\",\"isRunning\"]]]],[[\"default\"],[[[[1,\"\\n                Unassign\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[39,5],[[24,0,\"secondary\"],[4,[38,2],[\"click\",[28,[37,3],[\"showConfirmationDialog\",[30,0]],null]],null]],null,[[\"default\"],[[[[1,\"\\n                Close\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[]],null]],[\"@record\"],false,[\"if\",\"not\",\"on\",\"toggle\",\"modal-dialog\",\"button\",\"pipe\",\"perform\"]]",
    "moduleName": "congress-ap-client/components/tenant/team-list/member-list/action-cell.hbs",
    "isStrictMode": false
  });

  let TenantTeamListMemberListActionCellComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class TenantTeamListMemberListActionCellComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "errorHandler", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _defineProperty(this, "TENANT_ADMIN_ROLE", 2);

      _initializerDefineProperty(this, "showConfirmationDialog", _descriptor3, this);
    }

    get isTenantAdmin() {
      return +this.args.record.roleId === this.TENANT_ADMIN_ROLE;
    }

    *deleteUser() {
      try {
        yield this.ajax.request(`tenants/${this.args.column.tenantId}/teams/${this.args.column.teamId}/user/${this.args.record.id}`, {
          type: 'PATCH',
          data: JSON.stringify({
            is_deleted: 1
          })
        });
        yield this.args.column.refreshMembers();
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "showConfirmationDialog", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "deleteUser", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "deleteUser"), _class.prototype)), _class));
  _exports.default = TenantTeamListMemberListActionCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTeamListMemberListActionCellComponent);
});