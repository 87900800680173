define("congress-ap-client/services/ajax", ["exports", "ember-ajax/services/ajax", "congress-ap-client/config/environment"], function (_exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let AjaxService = (_dec = Ember.inject.service, (_class = class AjaxService extends _ajax.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _defineProperty(this, "contentType", 'application/json; charset=utf-8');

      _defineProperty(this, "host", _environment.default.APP.host || `${window.location.origin}/`);
    }

    get headers() {
      var _this$session$data$au;

      let headers = {};
      const authToken = (_this$session$data$au = this.session.data.authenticated) === null || _this$session$data$au === void 0 ? void 0 : _this$session$data$au.token;

      if (authToken) {
        headers['Authorization'] = `Bearer ${authToken}`;
      }

      return headers;
    }

    request() {
      return super.request(...arguments).catch(error => {
        var _payload;

        let payload = error.payload || null; // Convert to JSON API Error response format

        if (!((_payload = payload) !== null && _payload !== void 0 && _payload.errors)) {
          payload = {
            errors: [{
              status: error.status,
              title: payload
            }]
          };
        }

        throw payload;
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = AjaxService;
});