define("congress-ap-client/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KUYbccsd",
    "block": "[[[41,[30,0,[\"session\",\"isAuthenticated\"]],[[[1,\"    \"],[10,0],[14,0,\"app-container\"],[12],[1,\"\\n        \"],[8,[39,1],null,null,null],[1,\"\\n        \"],[10,0],[14,0,\"app-content\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"app-content-wrapper flex-by-column\"],[12],[1,\"\\n                \"],[8,[39,2],[[24,0,\"header\"]],null,null],[1,\"\\n                \"],[10,0],[14,0,\"app-main-content flex-grow-to-one flex-by-column\"],[12],[1,\"\\n                    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n                \"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[46,[28,[37,4],null,null],null,null,null],[1,\"\\n\"]],[]]],[8,[39,5],null,null,null]],[],false,[\"if\",\"app-navigation\",\"app-header\",\"component\",\"-outlet\",\"page-loader\"]]",
    "moduleName": "congress-ap-client/templates/application.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});