define("congress-ap-client/components/congress-listing/data-row/planners", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{@record.plannerCount}} Planners
  */
  {
    "id": "dTZkR+bt",
    "block": "[[[1,[30,1,[\"plannerCount\"]]],[1,\" Planners\"]],[\"@record\"],false,[]]",
    "moduleName": "congress-ap-client/components/congress-listing/data-row/planners.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});