define("congress-ap-client/components/tenant/team-list/member-list/action-filter-cell", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="inline-add-button" {{on 'click' (pipe (toggle 'showInlineAdd' this) (fn @column.toggleShowInlineAdd this.showInlineAdd))}} {{did-insert (fn @column.toggleShowInlineAdd this.showInlineAdd)}}>
      <img src={{if this.showInlineAdd '/images/cancel.svg' '/images/add.svg'}} />
  </div>
  */
  {
    "id": "14nu1/wa",
    "block": "[[[11,0],[24,0,\"inline-add-button\"],[4,[38,0],[\"click\",[28,[37,1],[[28,[37,2],[\"showInlineAdd\",[30,0]],null],[28,[37,3],[[30,1,[\"toggleShowInlineAdd\"]],[30,0,[\"showInlineAdd\"]]],null]],null]],null],[4,[38,4],[[28,[37,3],[[30,1,[\"toggleShowInlineAdd\"]],[30,0,[\"showInlineAdd\"]]],null]],null],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[52,[30,0,[\"showInlineAdd\"]],\"/images/cancel.svg\",\"/images/add.svg\"]],[12],[13],[1,\"\\n\"],[13]],[\"@column\"],false,[\"on\",\"pipe\",\"toggle\",\"fn\",\"did-insert\",\"if\"]]",
    "moduleName": "congress-ap-client/components/tenant/team-list/member-list/action-filter-cell.hbs",
    "isStrictMode": false
  });

  let TenantTeamListMemberListActionFilterCellComponent = (_dec = Ember._tracked, (_class = class TenantTeamListMemberListActionFilterCellComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "showInlineAdd", _descriptor, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "showInlineAdd", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = TenantTeamListMemberListActionFilterCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTeamListMemberListActionFilterCellComponent);
});