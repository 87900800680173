define("congress-ap-client/components/models-table/theme/pagination-numeric", ["exports", "ember-models-table/components/models-table/pagination-numeric"], function (_exports, _paginationNumeric) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#each this.visiblePageNumbers as |page|}}
    {{#if page.isLink}}
    <span
       class="page enabled {{if page.isActive 'page-active'}}"
      onclick={{fn this.gotoCustomPage page.label}}
    >
      {{page.label}}
    </span>
    {{else}}
      <span
        class="page diabled"
        disabled="disabled">
        {{page.label}}
      </span>
    {{/if}}
  {{/each}}
  
  */
  {
    "id": "jgdJrPqP",
    "block": "[[[42,[28,[37,1],[[28,[37,1],[[30,0,[\"visiblePageNumbers\"]]],null]],null],null,[[[41,[30,1,[\"isLink\"]],[[[1,\"  \"],[10,1],[15,0,[29,[\"page enabled \",[52,[30,1,[\"isActive\"]],\"page-active\"]]]],[15,\"onclick\",[28,[37,3],[[30,0,[\"gotoCustomPage\"]],[30,1,[\"label\"]]],null]],[12],[1,\"\\n    \"],[1,[30,1,[\"label\"]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"    \"],[10,1],[14,0,\"page diabled\"],[14,\"disabled\",\"disabled\"],[12],[1,\"\\n      \"],[1,[30,1,[\"label\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]],[1]],null]],[\"page\"],false,[\"each\",\"-track-array\",\"if\",\"fn\"]]",
    "moduleName": "congress-ap-client/components/models-table/theme/pagination-numeric.hbs",
    "isStrictMode": false
  });

  class ModelsTableThemePaginationNumericComponent extends _paginationNumeric.default {}

  _exports.default = ModelsTableThemePaginationNumericComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModelsTableThemePaginationNumericComponent);
});