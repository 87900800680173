define("congress-ap-client/mirage/scenarios/default", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(server) {
    /*
    Seed your development database using your factories.
    This data will not be loaded in your tests.
    */
    // Creating 20 Congresses with 5 Planners and 3 users.
    let number_of_congresses = 4;
    let number_of_audits_in_one_congress = 10;
    server.createList('congress', number_of_congresses, 'withPlanners', 'withUsers', 'withSessions', 'withAnnotations');
    server.createList('planner', 10);
    server.createList('tenant', 20, 'withTeams');
    server.createList('tenant-user', 20, 'withTeams');

    for (let i = 1; i <= number_of_congresses; i++) {
      server.createList('congress-audit', number_of_audits_in_one_congress, {
        congressId: i
      });
    }
  }
});