define("congress-ap-client/components/models-table-server-paginated", ["exports", "ember-models-table/components/models-table-server-paginated"], function (_exports, _modelsTableServerPaginated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modelsTableServerPaginated.default.extend({
    retainFilters: Ember.inject.service(),
    filterQueryParameters: Ember.computed(function () {
      return {
        globalFilter: 'search',
        sort: 'sort',
        sortDirection: 'sortDirection',
        page: 'page',
        pageSize: 'limit'
      };
    }),

    setQueryFilter(query, column, filterTitle, filter) {
      if (!('search' in query)) {
        query['search'] = {};
      }

      this.retainFilters.setFilter(this.attrs.tableId, filterTitle, filter);

      if (!Ember.isBlank(filter)) {
        query[filterTitle] = filter;
        query['search'][filterTitle] = filter;
      } else {
        delete query[filterTitle];
        delete query['search']['filterTitle'];
      }
    },

    singleColumnSortingWrapper(query, sortBy, sortDirection) {
      query[this.filterQueryParameters.sort] = sortBy;
      query[this.filterQueryParameters.sortDirection] = sortDirection;
      query['sort'] = {};
      query['sort'][sortBy] = sortDirection.toLowerCase();
      this.retainFilters.setSort(this.attrs.tableId, sortBy, sortDirection.toLowerCase());
      return query;
    },

    retrieveColumnAttributes() {
      this.columns.forEach(column => {
        column.filterString = this.retainFilters.getFilterFor(this.attrs.tableId, column.filteredBy ? column.filteredBy : column.propertyName);
        column.sortDirection = this.retainFilters.getSortFor(this.attrs.tableId, column.sortedBy ? column.sortedBy : column.propertyName);
        column.sortPrecedence = column.sortDirection ? 0 : undefined;
      });
    },

    init() {
      this._super(...arguments);

      this.retrieveColumnAttributes();
      this.addObserver('updateColumnsFlag', this, this.updateColumnsWrappper);
    },

    updateColumnsWrappper() {
      this.columnsAreUpdateable = true;
      this.retrieveColumnAttributes();
      this.updateColumns();
      this.columnsAreUpdateable = false;
    },

    async doQuery(store, modelName, query) {
      try {
        this.showTableSpinner(true);
        query['reqType'] = 'POST';
        query['limit'] = parseInt(query['pageSize']);
        query['offset'] = (query['page'] - 1) * query['pageSize'];
        let response = await store.query(modelName, query);
        this.set('filteredContent', response);
      } catch (error) {
        throw error;
      } finally {
        this.showTableSpinner(false);
      }
    }

  });

  _exports.default = _default;
});