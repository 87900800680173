define("congress-ap-client/helpers/prepend-url-scheme", ["exports", "congress-ap-client/utils/prepend-url-scheme"], function (_exports, _prependUrlScheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function prependUrlScheme(params
  /*, hash*/
  ) {
    return (0, _prependUrlScheme.default)(params[0]);
  });

  _exports.default = _default;
});