define("congress-ap-client/components/sessions-data-table/session-buzz-operations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='session-data-table-submenu'>
      <div class='submenu-label'>Populate</div>
      <div
          class='submenu-action {{if (not @actions.canEdit) 'disable'}}'
          onClick={{if @actions.canEdit (fn @actions.populate 'BUZZ_KEYWORDS')}}
      >Buzz Keywords</div>
      <div
          class='submenu-action {{if (not @actions.canEdit) 'disable'}}'
          onClick={{if @actions.canEdit (fn @actions.populate 'BUZZ_SCORE')}}
      >Buzz Score</div>
  </div>
  <div class='session-data-table-submenu-divider'></div>
  <div class='session-data-table-submenu'>
      <div class='submenu-label'>Download</div>
      <div
          class='submenu-action {{if (not @actions.canDownload) 'disable'}}'
          onClick={{if @actions.canDownload (perform @actions.download 'sessions/buzz_keywords/download')}}
      >Buzz Keywords</div>
  </div>
  <div class='session-data-table-submenu-divider'></div>
  <div class='session-data-table-submenu'>
      <div class='submenu-label'>Upload</div>
      <SessionsDataTable::FileUpload
          @upload={{@actions.upload}}
          @route='sessions/buzz_keywords'
          @disabled={{if (not @actions.canEdit) true}}
          class='{{if (not @actions.canEdit) 'disable'}}'
      />
  </div>
  */
  {
    "id": "xwv4DLYU",
    "block": "[[[10,0],[14,0,\"session-data-table-submenu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"submenu-label\"],[12],[1,\"Populate\"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"submenu-action \",[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],\"disable\"]]]],[15,\"onClick\",[52,[30,1,[\"canEdit\"]],[28,[37,2],[[30,1,[\"populate\"]],\"BUZZ_KEYWORDS\"],null]]],[12],[1,\"Buzz Keywords\"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"submenu-action \",[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],\"disable\"]]]],[15,\"onClick\",[52,[30,1,[\"canEdit\"]],[28,[37,2],[[30,1,[\"populate\"]],\"BUZZ_SCORE\"],null]]],[12],[1,\"Buzz Score\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"session-data-table-submenu-divider\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"session-data-table-submenu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"submenu-label\"],[12],[1,\"Download\"],[13],[1,\"\\n    \"],[10,0],[15,0,[29,[\"submenu-action \",[52,[28,[37,1],[[30,1,[\"canDownload\"]]],null],\"disable\"]]]],[15,\"onClick\",[52,[30,1,[\"canDownload\"]],[28,[37,3],[[30,1,[\"download\"]],\"sessions/buzz_keywords/download\"],null]]],[12],[1,\"Buzz Keywords\"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[10,0],[14,0,\"session-data-table-submenu-divider\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"session-data-table-submenu\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"submenu-label\"],[12],[1,\"Upload\"],[13],[1,\"\\n    \"],[8,[39,4],[[16,0,[29,[[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],\"disable\"]]]]],[[\"@upload\",\"@route\",\"@disabled\"],[[30,1,[\"upload\"]],\"sessions/buzz_keywords\",[52,[28,[37,1],[[30,1,[\"canEdit\"]]],null],true]]],null],[1,\"\\n\"],[13]],[\"@actions\"],false,[\"if\",\"not\",\"fn\",\"perform\",\"sessions-data-table/file-upload\"]]",
    "moduleName": "congress-ap-client/components/sessions-data-table/session-buzz-operations.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});