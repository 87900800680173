define("congress-ap-client/components/sessions-data-table/session-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <FileUpload @onChange={{perform @upload @route}} @disabled={{@disabled}}>
      <div class='upload-sessions'>
          <img src='/images/upload-placeholder.svg' />
           <span class='upload-text'>
              {{#if @upload.isRunning}}
                  Uploading...
              {{else}}
                  Upload Sessions CSV
              {{/if}}
          </span>
      </div>
  </FileUpload>
  */
  {
    "id": "ePQm+sld",
    "block": "[[[8,[39,0],null,[[\"@onChange\",\"@disabled\"],[[28,[37,1],[[30,1],[30,2]],null],[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"upload-sessions\"],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/upload-placeholder.svg\"],[12],[13],[1,\"\\n         \"],[10,1],[14,0,\"upload-text\"],[12],[1,\"\\n\"],[41,[30,1,[\"isRunning\"]],[[[1,\"                Uploading...\\n\"]],[]],[[[1,\"                Upload Sessions CSV\\n\"]],[]]],[1,\"        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@upload\",\"@route\",\"@disabled\"],false,[\"file-upload\",\"perform\",\"if\"]]",
    "moduleName": "congress-ap-client/components/sessions-data-table/session-upload.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});