define("congress-ap-client/utils/prepend-url-scheme", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = prependUrlScheme;

  function prependUrlScheme(url) {
    if (!/^https?:\/\//i.test(url)) {
      url = 'http://' + url; // https supported site will automatically redirect from http to https
    }

    return url;
  }
});