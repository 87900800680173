define("congress-ap-client/components/planner-synchronization", ["exports", "@glimmer/component", "ember-concurrency", "congress-ap-client/utils/socket", "congress-ap-client/utils/is-empty"], function (_exports, _component, _emberConcurrency, _socket, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Button
      class='primary planner-synchronization {{this.plannerSyncStatus}}'
      @loading={{this.syncPlanner.isRunning}}
      @disabled={{if (not-eq this.plannerSyncStatus this.PLANNER_SYNC_STATES.IDLE) true false}}
      {{on 'click' (perform this.syncPlanner)}}
  >
      {{#if (eq this.plannerSyncStatus this.PLANNER_SYNC_STATES.SUCCESS)}}
          Up to Date
      {{else}}
          <img src='/images/refresh.svg' />
          {{#if (eq this.plannerSyncStatus this.PLANNER_SYNC_STATES.INITIATED)}}
              Syncing
          {{else}}
              Sync to Planner
          {{/if}}
      {{/if}}
  </Button>
  */
  {
    "id": "FpNCEJf2",
    "block": "[[[8,[39,0],[[16,0,[29,[\"primary planner-synchronization \",[30,0,[\"plannerSyncStatus\"]]]]],[4,[38,3],[\"click\",[28,[37,4],[[30,0,[\"syncPlanner\"]]],null]],null]],[[\"@loading\",\"@disabled\"],[[30,0,[\"syncPlanner\",\"isRunning\"]],[52,[28,[37,2],[[30,0,[\"plannerSyncStatus\"]],[30,0,[\"PLANNER_SYNC_STATES\",\"IDLE\"]]],null],true,false]]],[[\"default\"],[[[[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"plannerSyncStatus\"]],[30,0,[\"PLANNER_SYNC_STATES\",\"SUCCESS\"]]],null],[[[1,\"        Up to Date\\n\"]],[]],[[[1,\"        \"],[10,\"img\"],[14,\"src\",\"/images/refresh.svg\"],[12],[13],[1,\"\\n\"],[41,[28,[37,5],[[30,0,[\"plannerSyncStatus\"]],[30,0,[\"PLANNER_SYNC_STATES\",\"INITIATED\"]]],null],[[[1,\"            Syncing\\n\"]],[]],[[[1,\"            Sync to Planner\\n\"]],[]]]],[]]]],[]]]]]],[],false,[\"button\",\"if\",\"not-eq\",\"on\",\"perform\",\"eq\"]]",
    "moduleName": "congress-ap-client/components/planner-synchronization.hbs",
    "isStrictMode": false
  });

  let PlannerSynchronizationComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, _dec6 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class PlannerSynchronizationComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "toast", _descriptor2, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor3, this);

      _defineProperty(this, "PLANNER_SYNC_STATES", {
        IDLE: 'idle',
        INITIATED: 'initiated',
        SUCCESS: 'success'
      });

      _defineProperty(this, "ROUTE", `congresses/${this.args.model.id}/sessions/sync`);

      _defineProperty(this, "timer", null);

      _initializerDefineProperty(this, "plannerSyncStatus", _descriptor4, this);

      this.args.socketListener.on('onSocketMessage', this.handleSocketServerMessage);
    }

    handleSocketServerMessage(data) {
      var _data$url;

      if (data && ((_data$url = data.url) === null || _data$url === void 0 ? void 0 : _data$url.slice(1)) === this.ROUTE && data.type && !(0, _isEmpty.default)(data.message)) {
        if (data.type === _socket.MESSAGE_TYPE.INITIATED) {
          this.plannerSyncStatus = this.PLANNER_SYNC_STATES.INITIATED;
        }

        if (data.type === _socket.MESSAGE_TYPE.SUCCESS) {
          this.plannerSyncStatus = this.PLANNER_SYNC_STATES.SUCCESS;
          this.timer = setTimeout(() => {
            this.plannerSyncStatus = this.PLANNER_SYNC_STATES.IDLE;
          }, 5000);
        }

        if (data.type === _socket.MESSAGE_TYPE.FAILURE) {
          this.errorHandler.notifyError(data.message);
          this.plannerSyncStatus = this.PLANNER_SYNC_STATES.IDLE;
        }
      }
    }

    *syncPlanner() {
      try {
        yield this.ajax.request(this.ROUTE, {
          type: 'POST'
        });
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

    willDestroy() {
      var _this$args$socketList;

      this.timer && clearTimeout(this.timer);
      (_this$args$socketList = this.args.socketListener) === null || _this$args$socketList === void 0 ? void 0 : _this$args$socketList.off('onSocketMessage', this.handleSocketServerMessage);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "plannerSyncStatus", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.PLANNER_SYNC_STATES.IDLE;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "handleSocketServerMessage", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "handleSocketServerMessage"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "syncPlanner", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "syncPlanner"), _class.prototype)), _class));
  _exports.default = PlannerSynchronizationComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlannerSynchronizationComponent);
});