define("congress-ap-client/components/models-table/theme/row-sorting-cell", ["exports", "@glimmer/component", "ember-models-table/components/models-table/row-sorting-cell"], function (_exports, _component, _rowSortingCell) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.column.componentForSortCell}}
      {{#let
          (component
              this.column.componentForSortCell
              column=this.column
              selectedItems=this.selectedItems
              expandedItems=this.expandedItems
              data=this.data
              themeInstance=this.themeInstance
              expandAllRows=this.expandAllRows
              toggleAllSelection=this.toggleAllSelection
              collapseAllRows=this.collapseAllRows
          )
          as |CellContent|
      }}
          {{#if (has-block)}}
              {{yield CellContent}}
          {{else}}
              <CellContent />
          {{/if}}
      {{/let}}
  {{else}}
      {{#if (has-block)}}
          {{yield}}
      {{else}}
          <div class="emt-column-title">
              {{this.column.title}}
              {{#if this.column.useSorting}}
                  <FaIcon
                      @icon={{if this.column.sortAsc 'sort-up' (if this.column.sortDesc 'sort-down' 'sort')}}
                  />
              {{/if}}
          </div>
      {{/if}}
  {{/if}}
  */
  {
    "id": "hdSGqO0w",
    "block": "[[[41,[30,0,[\"column\",\"componentForSortCell\"]],[[[44,[[50,[30,0,[\"column\",\"componentForSortCell\"]],0,null,[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\"],[[30,0,[\"column\"]],[30,0,[\"selectedItems\"]],[30,0,[\"expandedItems\"]],[30,0,[\"data\"]],[30,0,[\"themeInstance\"]],[30,0,[\"expandAllRows\"]],[30,0,[\"toggleAllSelection\"]],[30,0,[\"collapseAllRows\"]]]]]],[[[41,[48,[30,2]],[[[1,\"            \"],[18,2,[[30,1]]],[1,\"\\n\"]],[]],[[[1,\"            \"],[8,[30,1],null,null,null],[1,\"\\n\"]],[]]]],[1]]]],[]],[[[41,[48,[30,2]],[[[1,\"        \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"emt-column-title\"],[12],[1,\"\\n            \"],[1,[30,0,[\"column\",\"title\"]]],[1,\"\\n\"],[41,[30,0,[\"column\",\"useSorting\"]],[[[1,\"                \"],[8,[39,5],null,[[\"@icon\"],[[52,[30,0,[\"column\",\"sortAsc\"]],\"sort-up\",[52,[30,0,[\"column\",\"sortDesc\"]],\"sort-down\",\"sort\"]]]],null],[1,\"\\n\"]],[]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]]],[]]]],[\"CellContent\",\"&default\"],false,[\"if\",\"let\",\"component\",\"has-block\",\"yield\",\"fa-icon\"]]",
    "moduleName": "congress-ap-client/components/models-table/theme/row-sorting-cell.hbs",
    "isStrictMode": false
  });

  class ModelsTableThemeRowSortingCellComponent extends _rowSortingCell.default {}

  _exports.default = ModelsTableThemeRowSortingCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ModelsTableThemeRowSortingCellComponent);
});