define("congress-ap-client/components/tenant/tenant-member-list/name-cell", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if @record.isDeleted}}
      <div class='tenant-member-name'>
          <div class='short-version' {{did-insert this.setupElement}}>
              {{@record.name}}
          </div>
          {{#if this.isEllipsisActive}}
              <div class='long-version'>{{@record.name}}</div>
          {{/if}}
      </div>
  {{else}}
      {{#ember-inline-edit
          value=@record.name
          showSaveButton=false
          showCancelButton=false
          onSave=(perform @column.modifyUser @record)
          as |x onSave|
      }}
          {{#x.editable}}
              <div class='tenant-member-name'>
                  <div class='short-version' {{did-insert this.setupElement}}>
                      {{@record.name}}
                  </div>
                  {{#if this.isEllipsisActive}}
                      <div class='long-version'>{{@record.name}}</div>
                  {{/if}}
              </div>
          {{/x.editable}}
          {{#x.editor}}
              <Input value={{@record.name}} oninput={{action (mut @record.name) value='target.value'}} />
          {{/x.editor}}
      {{/ember-inline-edit}}
  {{/if}}
  */
  {
    "id": "i8Xfg+IL",
    "block": "[[[41,[30,1,[\"isDeleted\"]],[[[1,\"    \"],[10,0],[14,0,\"tenant-member-name\"],[12],[1,\"\\n        \"],[11,0],[24,0,\"short-version\"],[4,[38,1],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n            \"],[1,[30,1,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"],[41,[30,0,[\"isEllipsisActive\"]],[[[1,\"            \"],[10,0],[14,0,\"long-version\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"]],[]],[[[6,[39,2],null,[[\"value\",\"showSaveButton\",\"showCancelButton\",\"onSave\"],[[30,1,[\"name\"]],false,false,[28,[37,3],[[30,2,[\"modifyUser\"]],[30,1]],null]]],[[\"default\"],[[[[6,[30,3,[\"editable\"]],null,null,[[\"default\"],[[[[1,\"            \"],[10,0],[14,0,\"tenant-member-name\"],[12],[1,\"\\n                \"],[11,0],[24,0,\"short-version\"],[4,[38,1],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n                    \"],[1,[30,1,[\"name\"]]],[1,\"\\n                \"],[13],[1,\"\\n\"],[41,[30,0,[\"isEllipsisActive\"]],[[[1,\"                    \"],[10,0],[14,0,\"long-version\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"\\n\"]],[]],null],[1,\"            \"],[13],[1,\"\\n\"]],[]]]]],[6,[30,3,[\"editor\"]],null,null,[[\"default\"],[[[[1,\"            \"],[8,[39,4],[[16,2,[30,1,[\"name\"]]],[16,\"oninput\",[28,[37,5],[[30,0],[28,[37,6],[[30,1,[\"name\"]]],null]],[[\"value\"],[\"target.value\"]]]]],null,null],[1,\"\\n\"]],[]]]]]],[3,4]]]]]],[]]]],[\"@record\",\"@column\",\"x\",\"onSave\"],false,[\"if\",\"did-insert\",\"ember-inline-edit\",\"perform\",\"input\",\"action\",\"mut\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-member-list/name-cell.hbs",
    "isStrictMode": false
  });

  let TenantTenantMemberListNameCellComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class TenantTenantMemberListNameCellComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "element", _descriptor, this);
    }

    get isEllipsisActive() {
      return this.args.record.name && (!this.args.record.isDeleted ? this.args.column.modifyUser && !this.args.column.modifyUser.isRunning : true) && this.element && this.element.offsetWidth < this.element.scrollWidth;
    }

    setupElement(element) {
      this.element = element;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype)), _class));
  _exports.default = TenantTenantMemberListNameCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantMemberListNameCellComponent);
});