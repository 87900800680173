define("congress-ap-client/mirage/factories/congress", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name(i) {
      return `Congress ${_faker.default.lorem.words()} ${i}`;
    },

    congressStatusId: Math.floor(Math.random() * 4) + 1,
    categoryId: Math.floor(Math.random() * 5) + 1,
    totalSessions: Math.floor(Math.random() * 1001),

    verifiedSessions() {
      return Math.floor(Math.random() * this.totalSessions) + 1;
    },

    startDate: _faker.default.date.recent(),
    endDate: _faker.default.date.soon(),
    timezoneId: 'EST5EDT',

    permissions() {
      let i = 0,
          array = [];

      for (i = 0; i <= _faker.default.datatype.number(4); i++) {
        array.push(_faker.default.random.arrayElement(['create', 'read', 'edit', 'download']));
      }

      return array;
    },

    withPlanners: (0, _emberCliMirage.trait)({
      afterCreate(congress, server) {
        server.createList('planner', 5, {
          congress
        });
      }

    }),
    withUsers: (0, _emberCliMirage.trait)({
      afterCreate(congress, server) {
        server.createList('user', 3, {
          congress: [congress]
        });
      }

    }),
    withSessions: (0, _emberCliMirage.trait)({
      afterCreate(congress, server) {
        server.createList('session', 10, {
          congress
        });
      }

    }),
    withAnnotations: (0, _emberCliMirage.trait)({
      afterCreate(congress, server) {
        server.createList('annotation', 10, {
          congress
        });
      }

    })
  });

  _exports.default = _default;
});