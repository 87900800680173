define("congress-ap-client/utils/socket", ["exports", "congress-ap-client/utils/is-empty"], function (_exports, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MESSAGE_TYPE = _exports.ERROR_CODE = _exports.socket = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class socket {
    constructor(url, options) {
      _defineProperty(this, "websocket", null);

      _defineProperty(this, "url", null);

      _defineProperty(this, "options", {
        maxAttempts: 2,
        timeout: 1000
      });

      _defineProperty(this, "_counter", 0);

      _defineProperty(this, "_timer", 1);

      this.url = url;
      this.options = { ...this.options,
        ...options
      };
      this.connect();
    }

    connect() {
      this.websocket = new WebSocket(this.url, this.options.protocols || []);

      this.websocket.onopen = event => {
        var _this$options$onopen, _this$options;

        (_this$options$onopen = (_this$options = this.options).onopen) === null || _this$options$onopen === void 0 ? void 0 : _this$options$onopen.call(_this$options, event);
        this._counter = 0;
      };

      this.options.onmessage && (this.websocket.onmessage = this.options.onmessage);
      this.options.onerror && (this.websocket.onerror = this.options.onerror);

      this.websocket.onclose = event => {
        var _this$options$onclose, _this$options2;

        event.code === ERROR_CODE.NORMAL_CLOSURE || event.code === ERROR_CODE.GOING_AWAY || event.code === ERROR_CODE.NO_STATUS_RECEIVED || this.reconnect(event);
        (_this$options$onclose = (_this$options2 = this.options).onclose) === null || _this$options$onclose === void 0 ? void 0 : _this$options$onclose.call(_this$options2, event);
      };
    }

    reconnect(event) {
      if (this._timer && this._counter++ < this.options.maxAttempts) {
        this._timer = setTimeout(() => {
          var _this$options$onrecon, _this$options3;

          (_this$options$onrecon = (_this$options3 = this.options).onreconnect) === null || _this$options$onrecon === void 0 ? void 0 : _this$options$onrecon.call(_this$options3, event);
          this.connect();
        }, this.options.timeout);
      } else {
        var _this$options$onmaxim, _this$options4;

        (_this$options$onmaxim = (_this$options4 = this.options).onmaximum) === null || _this$options$onmaxim === void 0 ? void 0 : _this$options$onmaxim.call(_this$options4, event);
      }
    }

    send(data) {
      if (!(0, _isEmpty.default)(data) && typeof data === 'string') {
        this.websocket.send(data);
        return;
      }

      (true && !(false) && Ember.assert(`send expects string type as argument; you passed ${typeof data}`, false));
    }

    sendJSON(data) {
      if (!(0, _isEmpty.default)(data) && typeof data === 'object') {
        this.websocket.send(JSON.stringify(data));
        return;
      }

      (true && !(false) && Ember.assert(`sendJSON expects object type as argument; you passed ${typeof data}`, false));
    }

    getData(event) {
      let data = event === null || event === void 0 ? void 0 : event.data;

      try {
        return JSON.parse(data);
      } catch (error) {
        return data;
      }
    }

    close(code = ERROR_CODE.NORMAL_CLOSURE, reason) {
      clearTimeout(this._timer);
      this.websocket.close(code, reason);
    }

  }

  _exports.socket = socket;
  const ERROR_CODE = {
    NORMAL_CLOSURE: 1000,
    GOING_AWAY: 1001,
    PROTOCOL_ERROR: 1002,
    UNSUPPORTED_DATA: 1003,
    NO_STATUS_RECEIVED: 1005,
    ABNORMAL_CLOSURE: 1006,
    INVALID_PAYLOAD: 1007,
    POLICY_VIOLATION: 1008,
    MESSAGE_TOO_BIG: 1009,
    MISSING_EXTENSION: 1010,
    INTERNAL_ERROR: 1011,
    SERVICE_RESTART: 1012,
    TRY_AGAIN_LATER: 1013,
    BAD_GATEWAY: 1014
  };
  _exports.ERROR_CODE = ERROR_CODE;
  const MESSAGE_TYPE = {
    INITIATED: 'INITIATED',
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE',
    ALERT: 'ALERT'
  };
  _exports.MESSAGE_TYPE = MESSAGE_TYPE;
});