define("congress-ap-client/components/tenant/file-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <FileUpload @onChange={{@onSelect}} @disabled={{@disabled}}>
      <div class="upload-file" ...attributes>
          <img src="/images/upload.svg" />
          Upload CSV
      </div>
  </FileUpload>
  
  */
  {
    "id": "oefjIIyC",
    "block": "[[[8,[39,0],null,[[\"@onChange\",\"@disabled\"],[[30,1],[30,2]]],[[\"default\"],[[[[1,\"\\n    \"],[11,0],[24,0,\"upload-file\"],[17,3],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/upload.svg\"],[12],[13],[1,\"\\n        Upload CSV\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"@onSelect\",\"@disabled\",\"&attrs\"],false,[\"file-upload\"]]",
    "moduleName": "congress-ap-client/components/tenant/file-upload.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});