define("congress-ap-client/components/toggle-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='toggle-button' ...attributes>
      {{#if (has-block)}}
          <div class='toggle-text'>{{yield}}</div>
      {{/if}}
      <div class='toggle-bar'>
          <label class='switch'>
              <Input @type='checkbox' @disabled={{@disabled}} @checked={{@toggleValue}} {{on 'input' @onToggle}} />
              <span class='slider round'></span>
          </label>
      </div>
  </div>
  */
  {
    "id": "CFsgLWBI",
    "block": "[[[11,0],[24,0,\"toggle-button\"],[17,1],[12],[1,\"\\n\"],[41,[48,[30,5]],[[[1,\"        \"],[10,0],[14,0,\"toggle-text\"],[12],[18,5,null],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[10,0],[14,0,\"toggle-bar\"],[12],[1,\"\\n        \"],[10,\"label\"],[14,0,\"switch\"],[12],[1,\"\\n            \"],[8,[39,3],[[4,[38,4],[\"input\",[30,4]],null]],[[\"@type\",\"@disabled\",\"@checked\"],[\"checkbox\",[30,2],[30,3]]],null],[1,\"\\n            \"],[10,1],[14,0,\"slider round\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@disabled\",\"@toggleValue\",\"@onToggle\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"input\",\"on\"]]",
    "moduleName": "congress-ap-client/components/toggle-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});