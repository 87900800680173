define("congress-ap-client/components/session-data-table/date", ["exports", "@glimmer/component", "moment-timezone"], function (_exports, _component, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{this.date}}
  */
  {
    "id": "oZDODJPb",
    "block": "[[[1,[30,0,[\"date\"]]]],[],false,[]]",
    "moduleName": "congress-ap-client/components/session-data-table/date.hbs",
    "isStrictMode": false
  });

  class SessionDataTableDateComponent extends _component.default {
    get date() {
      let date = this.args.record[this.args.column.propertyName];
      return this.args.column.timezone && date && (0, _momentTimezone.default)(date).tz(this.args.column.timezone).format('YYYY-MM-DD HH:mm:ss');
    }

  }

  _exports.default = SessionDataTableDateComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SessionDataTableDateComponent);
});