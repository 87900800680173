define("congress-ap-client/components/planners/priority-summary", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="legend-section">
      {{#each this.priorityCountSummary as |priorityCount|}}
          <Legend>
              <:data ><span class='{{if (and this.arePrioritiesUnAssigned (not-eq priorityCount.id 0)) 'empty-placeholder'}}'>
                  {{if (or (not this.arePrioritiesUnAssigned) (eq priorityCount.id 0)) priorityCount.data}}
              </span></:data>
              <:label>{{priorityCount.name}}</:label>
          </Legend>
      {{/each}}
  </div>
  
  */
  {
    "id": "5hFvceIZ",
    "block": "[[[10,0],[14,0,\"legend-section\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"priorityCountSummary\"]]],null]],null],null,[[[1,\"        \"],[8,[39,2],null,null,[[\"data\",\"label\"],[[[[10,1],[15,0,[29,[[52,[28,[37,4],[[30,0,[\"arePrioritiesUnAssigned\"]],[28,[37,5],[[30,1,[\"id\"]],0],null]],null],\"empty-placeholder\"]]]],[12],[1,\"\\n                \"],[1,[52,[28,[37,6],[[28,[37,7],[[30,0,[\"arePrioritiesUnAssigned\"]]],null],[28,[37,8],[[30,1,[\"id\"]],0],null]],null],[30,1,[\"data\"]]]],[1,\"\\n            \"],[13]],[]],[[[1,[30,1,[\"name\"]]]],[]]]]],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"priorityCount\"],false,[\"each\",\"-track-array\",\"legend\",\"if\",\"and\",\"not-eq\",\"or\",\"not\",\"eq\"]]",
    "moduleName": "congress-ap-client/components/planners/priority-summary.hbs",
    "isStrictMode": false
  });

  class PlannersPrioritySummaryComponent extends _component.default {
    get priorityCountSummary() {
      return this.args.priorityCountSummary || [];
    }

    get isLoading() {
      return this.args.isLoading || false;
    }

    get arePrioritiesAssigned() {
      return this.args.arePrioritiesAssigned || false;
    }

  }

  _exports.default = PlannersPrioritySummaryComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, PlannersPrioritySummaryComponent);
});