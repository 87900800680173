define("congress-ap-client/components/tenant/team-list/planners", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      class="planners teams-module"
      {{did-update this.refreshPlanners @team.id}}>
      {{#if this.fetchPlanners.isRunning}}
          <div class="loader">
              <div class="search-spinner" />
          </div>
      {{else}}
          <div class="header">
              <div class="heading"> Planners </div>
              <div class="info">{{@team.plannersCount}} Congresses are connected to this team </div>
          </div>
          <CongressListing::PlannerList @planners={{this.planners}} @team={{@team}} @tenant={{@tenant}} class="tenant-planners" />
      {{/if}}
  </div>
  */
  {
    "id": "b+ei9SuH",
    "block": "[[[11,0],[24,0,\"planners teams-module\"],[4,[38,0],[[30,0,[\"refreshPlanners\"]],[30,1,[\"id\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"fetchPlanners\",\"isRunning\"]],[[[1,\"        \"],[10,0],[14,0,\"loader\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"search-spinner\"],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"heading\"],[12],[1,\" Planners \"],[13],[1,\"\\n            \"],[10,0],[14,0,\"info\"],[12],[1,[30,1,[\"plannersCount\"]]],[1,\" Congresses are connected to this team \"],[13],[1,\"\\n        \"],[13],[1,\"\\n        \"],[8,[39,2],[[24,0,\"tenant-planners\"]],[[\"@planners\",\"@team\",\"@tenant\"],[[30,0,[\"planners\"]],[30,1],[30,2]]],null],[1,\"\\n\"]],[]]],[13]],[\"@team\",\"@tenant\"],false,[\"did-update\",\"if\",\"congress-listing/planner-list\"]]",
    "moduleName": "congress-ap-client/components/tenant/team-list/planners.hbs",
    "isStrictMode": false
  });

  let TenantTeamListPlannersComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class TenantTeamListPlannersComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor2, this);

      _initializerDefineProperty(this, "planners", _descriptor3, this);

      _initializerDefineProperty(this, "teamId", _descriptor4, this);

      this.fetchPlanners.perform();
    }

    *fetchPlanners() {
      if (this.args.tenant.id && this.teamId) {
        try {
          this.planners = yield this.store.query('planner', {
            reqType: 'POST',
            adapterOptions: {
              tenantId: this.args.tenant.id,
              teamId: this.args.team.id
            }
          });
        } catch (error) {
          this.errorHandler.notifyError(error);
        }
      }
    }

    refreshPlanners() {
      setTimeout(() => {
        if (this.teamId != this.args.team.id) {
          this.teamId = this.args.team.id;
          this.fetchPlanners.perform();
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "planners", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "teamId", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.team ? this.args.team.id : null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "fetchPlanners", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPlanners"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshPlanners", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "refreshPlanners"), _class.prototype)), _class));
  _exports.default = TenantTeamListPlannersComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTeamListPlannersComponent);
});