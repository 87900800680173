define("congress-ap-client/components/congress-listing/data-row/verified-sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{@record.verifiedSessions}} / {{@record.totalSessions}}
  */
  {
    "id": "Nb9WDyPl",
    "block": "[[[1,[30,1,[\"verifiedSessions\"]]],[1,\" / \"],[1,[30,1,[\"totalSessions\"]]]],[\"@record\"],false,[]]",
    "moduleName": "congress-ap-client/components/congress-listing/data-row/verified-sessions.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});