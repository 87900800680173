define("congress-ap-client/mirage/factories/session", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    internalId(i) {
      return i;
    },

    displayId(i) {
      return i;
    },

    title(i) {
      return `Sessions ${_faker.default.lorem.words()} ${i}`;
    },

    type(i) {
      return `${_faker.default.lorem.words()} ${i}`;
    },

    abstract(i) {
      return `Sessions ${_faker.default.lorem.sentence()} ${i}`;
    },

    sessionTitle(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    abstractTitle(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    abstractLink(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    authors(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    institution(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    location(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    track(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    subTrack(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    clinicalTrial(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    syncFlag(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    buzzKeywords(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    buzzKeywordsOverrideFlag(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    buzz(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    buzzLogicType(i) {
      return `Sample ${_faker.default.lorem.sentence()} ${i}`;
    },

    startDate() {
      return new Date().toISOString();
    },

    endDate() {
      return new Date().toISOString();
    }

  });

  _exports.default = _default;
});