define("congress-ap-client/mirage/config", ["exports", "ember-inflector", "mock-socket", "ember-cli-mirage/response"], function (_exports, _emberInflector, _mockSocket, _response) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    // These comments are here to help you get started. Feel free to delete them.

    /*
    Config (with defaults).
     Note: these only affect routes defined *after* them!
    */
    // this.urlPrefix = '';    // make this `http://localhost:8080`, for example, if your API is on a different server
    // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
    // this.timing = 400;      // delay for each request, automatically set to 0 during testing

    /*
    Shorthand cheatsheet:
     this.get('/posts');
    this.post('/posts');
    this.get('/posts/:id');
    this.put('/posts/:id'); // or this.patch
    this.del('/posts/:id');
     https://www.ember-cli-mirage.com/docs/route-handlers/shorthands
    */
    setupMockSocketServer();
    this.post('/users/login', (schema, request) => {
      return {
        accessToken: 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IloxODciLCJ1c2VybmFtZSI6InN1c2htaXRoYS5yYW11IiwibWFpbCI6InN1c2htaXRoYS5yYW11QHpvb21yeC5jb20iLCJsYXN0X3ZhbGlkYXRlZCI6IjIwMjEtMDYtMThUMjI6NDg6MTAuNTkzMDQ4In0.gkA-3G0cxDgt0nL2z1wN5Q5BST1qVJUsZzWkGo9gHkBQ9SZvnV8boH4IqidG67eoTrDbHf0VIXX2cDt3muIGqlGgwtM-vv33DUVsN7CTrEOWGLvDeqyU_2fiOQTEbkCRbWhEockbQEFmU4zjZLTg74kH1_j1djFOgrBvEuWF33kOsO5RxwStG_VUTV6j-Jik21KrnX8-CSSW3RC6CrvMGPkuyYtZUsO5vYjzOzi9g9vj1JPseajK70xLy6MST18ehlmue3tw6YZWvDwKWzO_heAM_fpcti_eyVk_MgtUHE18OX1DbDt1bwoPf3J3oS0_QOohXB0jAgVBR1CCQxPeJYGGGS8889Cp7KwOlVf8E2WKSZI4lVrpIYIPSxbBnyUtP_9wH8KQhykGvT7udubn7ty-5hK8S-1jLT4x9htrnbb5mIARLNp6LT2LpkcUzTM7yIqd6gNcH90CLESbP8IpvVO4Y-uIYYH8EoLXVbLQHllT_9lq-wVbYXofBFs53hEuwYDabp9yzKL-PJoKu_VvpfoRBf2DeCwd7V-VeB8fWcqHc_VBXBu-rMFthk5H-eHfEP13kSPxrjQvT9PEmmN616VH2ANHJGwhQStvHB4UXmSKvZoWrcplOvl5V-fv9F5HKT_vofXPdQoZjVFjz4rJVPP-u2ASfAtuuf4ZB38PeQk',
        tokenType: 'bearer',
        user: {
          id: 'Z000',
          firstName: 'Mirage',
          lastName: 'Test',
          emailId: 'mirage.test@zoomrx.com'
        }
      };
    });
    this.post('congresses', (schema, request) => {
      return formatResponse(schema, schema.db.congresses, request, ['planners', 'users']);
    });
    this.get('congresses/:congress_id', (schema, request) => {
      return formatResponse(schema, schema.db.congresses.find(request.params.congress_id), request, ['planners']);
    });
    this.post('sessions', (schema, request) => {
      let {
        congress
      } = JSON.parse(request.requestBody);
      return formatResponse(schema, schema.db.sessions, // schema.db.congresses.find(congress, [
      //   // 'planners',
      //   // 'users',
      //   'sessions',
      // ]),
      request);
    });
    this.put('/congresses', (schema, request) => {
      let attrs = JSON.parse(request.requestBody);
      let newCongress = schema.congresses.create(attrs);
      return formatResponse(schema, newCongress.attrs, request);
    });
    this.post('congresses/:id/sessions', (schema, request) => {
      let sessions = schema.sessions.all();
      return {
        data: sessions.models
      };
    });
    this.get('congresses/:id/sessions/count', (schema, request) => {
      return {
        total: 100,
        unverified: 90,
        explicitVerified: 80,
        implicitVerified: 10
      };
    });
    this.get('/congresses/:congress_id/annotations', (schema, request) => {
      return {
        data: schema.db.annotations.where({
          congressId: request.params.congress_id
        })
      };
    });
    this.post('/congresses/:congress_id/audits', (schema, request) => {
      return formatResponse(schema, schema.db.congressAudits.where({
        congressId: request.params.congress_id
      }), request, []);
    });
    this.get('/congresses/statuses', () => {
      return {
        data: [{
          id: 5,
          name: 'Titles yet to be Released'
        }, {
          id: 1,
          name: 'Titles Released'
        }, {
          id: 2,
          name: 'Late Breaking Abstracts Released'
        }, {
          id: 3,
          name: 'Congress Ongoing'
        }, {
          id: 4,
          name: 'Congress Completed'
        }]
      };
    });
    this.get('/congresses/timezones', () => {
      return {
        data: [{
          id: 'EST5EDT'
        }, {
          id: 'PST8PDT'
        }]
      };
    });
    this.get('/congresses/categories', () => {
      return {
        data: [{
          id: 1,
          name: 'ongoing'
        }, {
          id: 2,
          name: 'upcoming'
        }, {
          id: 3,
          name: 'recently completed'
        }, {
          id: 4,
          name: 'completed'
        }, {
          id: 5,
          name: 'archived'
        }]
      };
    });
    this.get('/users/permissions', () => {
      return {
        data: {
          congress: ['read', 'edit', 'create', 'download'],
          tenant: ['planner_delete', 'read', 'edit', 'user_manage', 'create', 'planner_create', 'delete', 'planner_edit', 'planner_read']
        }
      };
    });
    this.post('planners/:planner_id/publish', (schema, request) => {
      return new _response.default(200);
    });
    this.get('planners/:planner_id', (schema, request) => {
      return formatResponse(schema, schema.db.planners.find(request.params.planner_id), request, ['tenants']);
    });
    this.patch('planners/:planner_id', (schema, request) => {
      // let attrs = normalizedRequestAttrs();
      // return {
      //     data: schema.db.planners.find(request.params.planner_id).update(attrs),
      // };
      return new _response.default(200);
    });
    this.put('/planners', (schema, request) => {
      let attrs = JSON.parse(request.requestBody);
      let newPlanner = schema.planners.create(attrs);
      return formatResponse(schema, newPlanner.attrs, request);
    });
    this.get('/planners/congresses', (schema, request) => {
      return {
        data: {
          ongoing: [{
            id: 1,
            name: 'congress 1'
          }, {
            id: 3,
            name: 'congress 3'
          }],
          upcoming: [{
            id: 2,
            name: 'congress 2'
          }],
          recently_completed: [],
          archived: []
        }
      };
    });
    this.get('/planners/tenants', (schema, request) => {
      return {
        data: [{
          id: 'pfizer_saks',
          name: 'pfizer',
          teams: [{
            id: 1,
            name: 'Oncology'
          }, {
            id: 2,
            name: 'Cancer'
          }]
        }, {
          id: 'oxford_saks',
          name: 'oxford',
          teams: [{
            id: 1,
            name: 'Oncology'
          }, {
            id: 2,
            name: 'Cancer'
          }]
        }]
      };
    });
    this.get('/planners/:planner_id/count', (schema, request) => {
      return {
        unassigned: 5931,
        flash: 1,
        high: 78,
        internal: 0,
        medium: 40,
        low: 0,
        notRelevant: 1
      };
    });
    this.post('/planners/:planner_id/sessions', (schema, request) => {
      return {
        data: [{
          id: 64599,
          internalId: null,
          abstractId: '6515',
          sessionType: 'Oral Abstract Session',
          sessionTitle: 'Health Services Research, Clinical Informatics, and Quality of Care II',
          abstractTitle: 'Integrating psychosocial care into routine cancer care: a stepped-wedge design cluster randomized controlled trial (SWD-RCT) to evaluate effectiveness of the HuCare Quality Improvement Strategy (HQIS) on health-related quality of life (HRQoL).',
          abstractLink: '',
          authors: 'Rodolfo Passalacqua, Elisa Iezzi, Maria Antonietta Annunziata, Claudio Verusio, Carmine Pinto, Mario Airoldi, Marcello Aragona, Francesca Caputo, Saverio Cinieri, Paolo Giordani, Stefania Gori, Rodolfo Mattioli, Silvia Novello, Antonio Pazzola, Giuseppe Procopio, Antonio Russo, Cosimo Sacco, Giuseppina Sarobba, Filippo Zerilli, Caterina Caminiti,',
          startDate: '2019-06-04T00:00:00',
          endDate: '2019-06-04T00:00:00',
          disease: ['Cancer'],
          comparatorDrug: [],
          drugClass: [],
          drug: [],
          sponsor: ['Biofortis', 'Lonza', 'Sino Medical Sciences Technology', 'Vertex Pharmaceuticals, Inc.', 'Polpharma Biologics'],
          indication: [],
          primaryDrug: [],
          secondaryDrug: [],
          patientSubgroup: '',
          nctAcronym: ['RCT'],
          buzz: 100.0,
          buzzKeywordsOverrideFlag: 0.0,
          must: ['RCT'],
          should: ['Vertex Pharmaceuticals, Inc.', 'Lonza', 'Biofortis', 'Sino Medical Sciences Technology', 'Polpharma Biologics'],
          mustNot: [],
          shouldBoost: ['Cancer'],
          addedTweets: [],
          deletedTweets: [],
          minShould: 1,
          buzzLogicType: 1.0,
          priority: 1
        }, {
          id: 64598,
          internalId: null,
          abstractId: '10015',
          sessionType: 'Oral Abstract Session',
          sessionTitle: 'Pediatric Oncology II',
          abstractTitle: 'Risk prediction of anthracycline-related cardiomyopathy (AC) in childhood cancer survivors (CCS): A COG-ALTE03N1 and CCSS report.',
          abstractLink: '',
          authors: 'Xuexia Wang, Yanjun Chen, Lindsey Hageman, Purnima Singh, Wendy Landier, Javier G. Blanco, Douglas S. Hawkins, A. Kim Ritchey, Jill P. Ginsberg, Naomi J. Winick, Yadav Sapkota, Yutaka Yasui, Lindsay M. Morton, Gregory T. Armstrong, Kevin C. Oeffinger, Melissa M. Hudson, Eric Jessen Chow, Leslie L. Robison, Mary V. Relling, Smita Bhatia',
          startDate: '2019-06-04T00:00:00',
          endDate: '2019-06-04T00:00:00',
          disease: ['Cancer', 'Cardiomyopathy'],
          comparatorDrug: [],
          drugClass: ['Anthracycline'],
          drug: [],
          sponsor: [],
          indication: [],
          primaryDrug: [],
          secondaryDrug: [],
          patientSubgroup: ['Childhood', 'Paediatric'],
          nctAcronym: [],
          buzz: 0.0,
          buzzKeywordsOverrideFlag: 0.0,
          must: [],
          should: [],
          mustNot: [],
          shouldBoost: [],
          addedTweets: [],
          deletedTweets: [],
          minShould: 1,
          buzzLogicType: 0.0,
          priority: 4
        }, {
          id: 64597,
          internalId: null,
          abstractId: '6515',
          sessionType: 'Oral Abstract Session',
          sessionTitle: 'Health Services Research, Clinical Informatics, and Quality of Care II',
          abstractTitle: 'Integrating psychosocial care into routine cancer care: a stepped-wedge design cluster randomized controlled trial (SWD-RCT) to evaluate effectiveness of the HuCare Quality Improvement Strategy (HQIS) on health-related quality of life (HRQoL).',
          abstractLink: '',
          authors: 'Rodolfo Passalacqua, Elisa Iezzi, Maria Antonietta Annunziata, Claudio Verusio, Carmine Pinto, Mario Airoldi, Marcello Aragona, Francesca Caputo, Saverio Cinieri, Paolo Giordani, Stefania Gori, Rodolfo Mattioli, Silvia Novello, Antonio Pazzola, Giuseppe Procopio, Antonio Russo, Cosimo Sacco, Giuseppina Sarobba, Filippo Zerilli, Caterina Caminiti,',
          startDate: '2019-06-04T00:00:00',
          endDate: '2019-06-04T00:00:00',
          disease: ['Cancer'],
          comparatorDrug: [],
          drugClass: [],
          drug: [],
          sponsor: ['Biofortis', 'Lonza', 'Sino Medical Sciences Technology', 'Vertex Pharmaceuticals, Inc.', 'Polpharma Biologics'],
          indication: [],
          primaryDrug: [],
          secondaryDrug: [],
          patientSubgroup: '',
          nctAcronym: ['RCT'],
          buzz: 100.0,
          buzzKeywordsOverrideFlag: 0.0,
          must: ['RCT'],
          should: ['Vertex Pharmaceuticals, Inc.', 'Lonza', 'Biofortis', 'Sino Medical Sciences Technology', 'Polpharma Biologics'],
          mustNot: [],
          shouldBoost: ['Cancer'],
          addedTweets: [],
          deletedTweets: [],
          minShould: 1,
          buzzLogicType: 1.0,
          priority: 1
        }, {
          id: 64596,
          internalId: null,
          abstractId: '10015',
          sessionType: 'Oral Abstract Session',
          sessionTitle: 'Pediatric Oncology II',
          abstractTitle: 'Risk prediction of anthracycline-related cardiomyopathy (AC) in childhood cancer survivors (CCS): A COG-ALTE03N1 and CCSS report.',
          abstractLink: '',
          authors: 'Xuexia Wang, Yanjun Chen, Lindsey Hageman, Purnima Singh, Wendy Landier, Javier G. Blanco, Douglas S. Hawkins, A. Kim Ritchey, Jill P. Ginsberg, Naomi J. Winick, Yadav Sapkota, Yutaka Yasui, Lindsay M. Morton, Gregory T. Armstrong, Kevin C. Oeffinger, Melissa M. Hudson, Eric Jessen Chow, Leslie L. Robison, Mary V. Relling, Smita Bhatia',
          startDate: '2019-06-04T00:00:00',
          endDate: '2019-06-04T00:00:00',
          disease: ['Cancer', 'Cardiomyopathy'],
          comparatorDrug: [],
          drugClass: ['Anthracycline'],
          drug: [],
          sponsor: [],
          indication: [],
          primaryDrug: [],
          secondaryDrug: [],
          patientSubgroup: ['Childhood', 'Paediatric'],
          nctAcronym: [],
          buzz: 0.0,
          buzzKeywordsOverrideFlag: 0.0,
          must: [],
          should: [],
          mustNot: [],
          shouldBoost: [],
          addedTweets: [],
          deletedTweets: [],
          minShould: 1,
          buzzLogicType: 0.0,
          priority: 4
        }, {
          id: 64595,
          internalId: null,
          abstractId: '6515',
          sessionType: 'Oral Abstract Session',
          sessionTitle: 'Health Services Research, Clinical Informatics, and Quality of Care II',
          abstractTitle: 'Integrating psychosocial care into routine cancer care: a stepped-wedge design cluster randomized controlled trial (SWD-RCT) to evaluate effectiveness of the HuCare Quality Improvement Strategy (HQIS) on health-related quality of life (HRQoL).',
          abstractLink: '',
          authors: 'Rodolfo Passalacqua, Elisa Iezzi, Maria Antonietta Annunziata, Claudio Verusio, Carmine Pinto, Mario Airoldi, Marcello Aragona, Francesca Caputo, Saverio Cinieri, Paolo Giordani, Stefania Gori, Rodolfo Mattioli, Silvia Novello, Antonio Pazzola, Giuseppe Procopio, Antonio Russo, Cosimo Sacco, Giuseppina Sarobba, Filippo Zerilli, Caterina Caminiti,',
          startDate: '2019-06-04T00:00:00',
          endDate: '2019-06-04T00:00:00',
          disease: ['Cancer'],
          comparatorDrug: [],
          drugClass: [],
          drug: [],
          sponsor: ['Biofortis', 'Lonza', 'Sino Medical Sciences Technology', 'Vertex Pharmaceuticals, Inc.', 'Polpharma Biologics'],
          indication: [],
          primaryDrug: [],
          secondaryDrug: [],
          patientSubgroup: '',
          nctAcronym: ['RCT'],
          buzz: 100.0,
          buzzKeywordsOverrideFlag: 0.0,
          must: ['RCT'],
          should: ['Vertex Pharmaceuticals, Inc.', 'Lonza', 'Biofortis', 'Sino Medical Sciences Technology', 'Polpharma Biologics'],
          mustNot: [],
          shouldBoost: ['Cancer'],
          addedTweets: [],
          deletedTweets: [],
          minShould: 1,
          buzzLogicType: 1.0,
          priority: 1
        }, {
          id: 64594,
          internalId: null,
          abstractId: '10015',
          sessionType: 'Oral Abstract Session',
          sessionTitle: 'Pediatric Oncology II',
          abstractTitle: 'Risk prediction of anthracycline-related cardiomyopathy (AC) in childhood cancer survivors (CCS): A COG-ALTE03N1 and CCSS report.',
          abstractLink: '',
          authors: 'Xuexia Wang, Yanjun Chen, Lindsey Hageman, Purnima Singh, Wendy Landier, Javier G. Blanco, Douglas S. Hawkins, A. Kim Ritchey, Jill P. Ginsberg, Naomi J. Winick, Yadav Sapkota, Yutaka Yasui, Lindsay M. Morton, Gregory T. Armstrong, Kevin C. Oeffinger, Melissa M. Hudson, Eric Jessen Chow, Leslie L. Robison, Mary V. Relling, Smita Bhatia',
          startDate: '2019-06-04T00:00:00',
          endDate: '2019-06-04T00:00:00',
          disease: ['Cancer', 'Cardiomyopathy'],
          comparatorDrug: [],
          drugClass: ['Anthracycline'],
          drug: [],
          sponsor: [],
          indication: [],
          primaryDrug: [],
          secondaryDrug: [],
          patientSubgroup: ['Childhood', 'Paediatric'],
          nctAcronym: [],
          buzz: 0.0,
          buzzKeywordsOverrideFlag: 0.0,
          must: [],
          should: [],
          mustNot: [],
          shouldBoost: [],
          addedTweets: [],
          deletedTweets: [],
          minShould: 1,
          buzzLogicType: 0.0,
          priority: 4
        }],
        meta: {
          total: 2648
        }
      };
    });
    this.get('/planners/:planner_id/scheduler', (schema, request) => {
      return {
        data: {
          name: 'Test Scheduler',
          interval: 5,
          time: '12:00:00',
          session_types: ['Scientific Programme'],
          priorities: ['High', 'Medium'],
          destination_path: '/American Society of Hematology (ASH)/2022/10th Dec/Posters and Slides'
        }
      };
    });
    this.delete('/planners/:planner_id/scheduler', (schema, request) => {
      return new _response.default(200);
    });
    this.post('/planners/:planner_id/scheduler/force_initiate', (schema, request) => {
      return new _response.default(200);
    });
    this.get('/planners/:planner_id/session_types', (schema, request) => {
      return {
        data: ['Scientific Programme', 'Education Session']
      };
    });
    this.get('/planners/priorities', (schema, request) => {
      return {
        data: [{
          id: 10,
          name: 'Flash',
          rank: 1
        }, {
          id: 1,
          name: 'High',
          rank: 2
        }, {
          id: 2,
          name: 'Internal',
          rank: 3
        }, {
          id: 3,
          name: 'Medium',
          rank: 4
        }, {
          id: 4,
          name: 'Low',
          rank: 5
        }, {
          id: 5,
          name: 'Not Relevant',
          rank: 6
        }]
      };
    });
    this.post('/tenants', (schema, request) => {
      return formatResponse(schema, schema.db.tenants, request, ['teams']);
    });
    this.get('/tenants/:id', (schema, request) => {
      return formatResponse(schema, schema.db.tenants.find(request.params.id), request, ['teams']);
    });
    this.patch('/tenants/:id', (schema, request) => {
      // let attrs = this.normalizedRequestAttrs();
      // return {
      //     data: schema.db.tenants.find(request.params.id).update(attrs)
      // };
      return new _response.default(200);
    });
    this.put('/tenants/:tenant_id/teams', (schema, request) => {
      let attrs = JSON.parse(request.requestBody);
      let newTeam = schema.planners.create(attrs);
      return formatResponse(schema, newTeam.attrs, request);
    });
    this.post('/tenants/:tenant_id/teams', (schema, request) => {
      return formatResponse(schema, schema.db.teams.where({
        tenantId: request.params.tenant_id
      }), request, []);
    });
    this.post('/tenants/:tenant_id/teams/:team_id/planners', (schema, request) => {
      return {
        data: [{
          id: 1,
          name: '2021 Planner for team',
          tenantName: 'pfizer',
          teamName: 'oncology',
          isPublished: 1
        }, {
          id: 2,
          name: '2021 Planner 2 for team',
          tenantName: 'astrazeneca',
          teamName: 'breast cancer',
          isPublished: 0
        }, {
          id: 3,
          name: '2021 Planner 2 for team',
          tenantName: 'astrazeneca',
          teamName: 'breast cancer',
          isPublished: 1
        }, {
          id: 4,
          name: '2021 Planner 2 for team',
          tenantName: 'astrazeneca',
          teamName: 'breast cancer',
          isPublished: 0
        }, {
          id: 5,
          name: '2021 Planner 2 for team',
          tenantName: 'astrazeneca',
          teamName: 'breast cancer',
          isPublished: 1
        }, {
          id: 6,
          name: '2021 Planner 2 for team',
          tenantName: 'astrazeneca',
          teamName: 'breast cancer',
          isPublished: 0
        }, {
          id: 7,
          name: '2021 Planner 2 for team',
          tenantName: 'astrazeneca',
          teamName: 'breast cancer',
          isPublished: 1
        }],
        meta: {
          offset: 0,
          limit: 5,
          total: 7
        }
      };
    });
    this.post('/tenants/:id/users', (schema, request) => {
      return formatResponse(schema, schema.db.tenantUsers, request, ['teams']);
    });
    this.patch('/tenants/:id/user/:id', (schema, request) => {
      return new _response.default(200);
    });
    this.post('/tenants/:id/teams/:id/users', (schema, request) => {
      return formatResponse(schema, schema.db.tenantUsers, request);
    });
    this.patch('tenants/:id/teams/:id/user/:id', (schema, request) => {
      return formatResponse(schema, schema.db.tenantUsers, request);
    });
    this.get('/tenants/:id/teams/:id/other_users', (schema, request) => {
      return formatResponse(schema, schema.db.tenantUsers, request);
    });
    this.put('/tenants/:id/teams/:id/user', (schema, request) => {
      return new _response.default(200);
    });

    function setupMockSocketServer() {
      const URL = 'ws://localhost:4200/ws';
      const MESSAGES = [{
        url: '/congresses/1/sessions/keywords/populate',
        type: 'INITIATED',
        message: 'The KG populating in the background'
      }, {
        url: '/congresses/1/sessions/buzz_keywords/populate',
        type: 'ALERT',
        message: 'Populate keywords before buzz operations'
      }, {
        url: '/congresses/1/sessions/keywords/populate',
        type: 'SUCCESS',
        message: 'KG populated successfully!!'
      }, {
        url: '/congresses/2/sessions/keywords/populate',
        type: 'INITIATED',
        message: 'The KG populating in the background'
      }, {
        url: '/congresses/2/sessions/keywords/populate',
        type: 'FAILURE',
        message: 'OOPS !! KG population is unsuccessfully!!'
      }, {
        url: '/congresses/1/sessions/buzz_keywords/populate',
        type: 'INITIATED',
        message: 'The buzz keywords populating in the background'
      }];
      const mockServer = new _mockSocket.Server(URL);
      mockServer.on('connection', socket => {
        let timer = 1000,
            delay = 5000,
            count = 0;
        MESSAGES.forEach(message => {
          setTimeout(() => {
            socket.send(JSON.stringify(message));
          }, timer + delay * count++);
        });
        socket.on('message', data => {
          console.log('Server received', data);
        });
      });
    } // formats the responsePayload by adding top-level keys like data, meta


    function formatResponse(schema, responseData, request, includeRelations = []) {
      let responsePayload = [];

      try {
        if (Array.isArray(responseData)) {
          console.log(includeRelations);
          let {
            offset = 0,
            limit = 5
          } = Object.keys(request.queryParams).length && request.queryParams.limit ? request.queryParams : JSON.parse(request.requestBody) ? JSON.parse(request.requestBody) : {};
          offset = parseInt(offset);
          limit = parseInt(limit);
          responsePayload = {
            data: responseData.slice(offset, offset + limit).map(row => fetchRelationalData(schema, row, includeRelations)),
            meta: {
              total: responseData.length,
              offset: offset,
              limit: limit
            }
          };
        } else {
          responsePayload = {
            data: fetchRelationalData(schema, responseData, includeRelations)
          };
        }
      } catch (error) {
        console.log(error);
      }

      return responsePayload;
    } // formats data to get details for relational attributes


    function fetchRelationalData(schema, responseData, includeRelations = []) {
      let result = {};
      Object.keys(responseData).forEach(key => {
        if (responseData[key] && key.endsWith('Ids')) {
          let modelName = (0, _emberInflector.pluralize)(key.slice(0, -3));

          if (includeRelations.length || includeRelations.includes(modelName)) {
            result[modelName] = schema.db[modelName].find(responseData[key]);
          }
        } else {
          result[key] = responseData[key];
        }
      });
      return result;
    }
  }
});