define("congress-ap-client/components/congress-audit-table/status-col", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="status-col--{{if (eq @record.transaction.outcome 'SUCCESS') 'success' 'failure'}}">{{@record.transaction.outcome}}</div>
  */
  {
    "id": "FSzT+MSH",
    "block": "[[[10,0],[15,0,[29,[\"status-col--\",[52,[28,[37,1],[[30,1,[\"transaction\",\"outcome\"]],\"SUCCESS\"],null],\"success\",\"failure\"]]]],[12],[1,[30,1,[\"transaction\",\"outcome\"]]],[13]],[\"@record\"],false,[\"if\",\"eq\"]]",
    "moduleName": "congress-ap-client/components/congress-audit-table/status-col.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});