define("congress-ap-client/routes/planners/planner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlannersPlannerRoute = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class PlannersPlannerRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "congressRoute", 'congress-view');

      _initializerDefineProperty(this, "plannerPriority", _descriptor, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor2, this);

      _initializerDefineProperty(this, "congressCategory", _descriptor3, this);
    }

    beforeModel() {
      this.plannerPriority.fetchPriorities();
      return this.congressCategory.fetchCategories();
    }

    model(params) {
      return this.store.findRecord('planner', params.planner_id, {
        adapterOptions: {
          include: 'congress,tenant.teams'
        }
      });
    }

    afterModel(model, transition) {
      if (transition.from) {
        if (!transition.from.name.includes(this.congressRoute)) {
          let controller = this.controllerFor(this.routeName);

          if (controller) {
            controller.set('routedFromCongress', false);
          }
        }
      }
    }

    error(error) {
      this.errorHandler.notifyError(error);
      this.transitionTo('congress-view');
    }

    resetController(controller, isExiting, transition) {
      if (isExiting) {
        controller.set('reenteredPlannerTitle', null);
        controller.set('isPublished', false);
        controller.set('showDeletePlannerDialog', false);
        controller.set('routedFromCongress', true);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "plannerPriority", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "congressCategory", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "afterModel", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "afterModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "error", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "error"), _class.prototype)), _class));
  _exports.default = PlannersPlannerRoute;
});