define("congress-ap-client/components/file-upload", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Input
      id={{this.for}}
      type='file'
      value={{this.value}}
      accept={{this.accept}}
      multiple={{this.multiple}}
      disabled={{this.disabled}}
      oninput={{action (pipe (action @onChange value='target.files')  (action this.clearInput) )}}
      hidden
      {{did-insert this.setupElement}}
  />
  <label for={{this.for}} ...attributes>
      {{yield}}
  </label>
  */
  {
    "id": "XBS64w2i",
    "block": "[[[8,[39,0],[[16,1,[30,0,[\"for\"]]],[16,2,[30,0,[\"value\"]]],[16,\"accept\",[30,0,[\"accept\"]]],[16,\"multiple\",[30,0,[\"multiple\"]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[16,\"oninput\",[28,[37,1],[[30,0],[28,[37,2],[[28,[37,1],[[30,0],[30,1]],[[\"value\"],[\"target.files\"]]],[28,[37,1],[[30,0],[30,0,[\"clearInput\"]]],null]],null]],null]],[24,\"hidden\",\"\"],[24,4,\"file\"],[4,[38,3],[[30,0,[\"setupElement\"]]],null]],null,null],[1,\"\\n\"],[11,\"label\"],[16,\"for\",[30,0,[\"for\"]]],[17,2],[12],[1,\"\\n    \"],[18,3,null],[1,\"\\n\"],[13]],[\"@onChange\",\"&attrs\",\"&default\"],false,[\"input\",\"action\",\"pipe\",\"did-insert\",\"yield\"]]",
    "moduleName": "congress-ap-client/components/file-upload.hbs",
    "isStrictMode": false
  });

  let FileUploadComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class FileUploadComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "element", _descriptor, this);

      _initializerDefineProperty(this, "value", _descriptor2, this);
    }

    setupElement(element) {
      this.element = element;
    }

    clearInput() {
      this.value = null;
    }

    get for() {
      return this.args.for ?? `file-upload-${Ember.guidFor(this.element)}`;
    }

    get accept() {
      return this.args.accept ?? '.csv';
    }

    get multiple() {
      return this.args.multiple ?? false;
    }

    get disabled() {
      return this.args.disabled ?? false;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "value", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearInput", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clearInput"), _class.prototype)), _class));
  _exports.default = FileUploadComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, FileUploadComponent);
});