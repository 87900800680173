define("congress-ap-client/components/checkbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      class='zx-checkbox {{if @selected 'zx-checkbox--selected'}}'
      {{on 'click' (fn @onChange @value @selected)}}
  >
      <div class='zx-checkbox__box'>
          {{#if @selected}}
              <img src='/images/checkbox-tick.svg' />
          {{/if}}
      </div>
      <div class='zx-checkbox__value'>
          {{@value}}
      </div>
  </div>
  */
  {
    "id": "I/y06d61",
    "block": "[[[11,0],[16,0,[29,[\"zx-checkbox \",[52,[30,1],\"zx-checkbox--selected\"]]]],[4,[38,1],[\"click\",[28,[37,2],[[30,2],[30,3],[30,1]],null]],null],[12],[1,\"\\n    \"],[10,0],[14,0,\"zx-checkbox__box\"],[12],[1,\"\\n\"],[41,[30,1],[[[1,\"            \"],[10,\"img\"],[14,\"src\",\"/images/checkbox-tick.svg\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"zx-checkbox__value\"],[12],[1,\"\\n        \"],[1,[30,3]],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"@selected\",\"@onChange\",\"@value\"],false,[\"if\",\"on\",\"fn\"]]",
    "moduleName": "congress-ap-client/components/checkbox.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});