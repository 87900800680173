define("congress-ap-client/adapters/session", ["exports", "congress-ap-client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SessionAdapter extends _application.default {
    urlForFindAll(modelName, snapshot) {
      return `/congresses/${snapshot.adapterOptions.congressId}/sessions`;
    }

    urlForQuery(query, modelName) {
      if (query.planner) {
        return `${this.host}/planners/${query.planner}/sessions`;
      }

      return `${this.host}/congresses/${query.congress}/sessions`;
    }

  }

  _exports.default = SessionAdapter;
});