define("congress-ap-client/components/congress-listing/planner-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div ...attributes class='planner'>
      <div class="list-of-planner-cards">
          {{#each @planners as |planner|}}
              <LinkTo @route='planners.planner' @model={{planner.id}}>
                  <div class='planner-card'>
                      <div class='planner-details'>
                          <span class='planner-card--title'>
                              <span
                                  class='planner-bulletin {{if planner.isPublished 'published-planner' 'unpublished-planner'}}'>
                              </span>
                              {{planner.name}}
                          </span>
                          <span class='planner-card--team'>{{planner.teamName}} / {{planner.tenantName}}</span>
                      </div>
                  </div>
              </LinkTo>
          {{/each}}
      </div>
      {{#if (can 'read congress' @congress)}}
          <LinkTo class="link-to-new-planner" @route='planners.new' @query={{hash congressId=@congress.id teamId=@team.id tenantId=@tenant.id}}>
              <Button
                  class='primary new-planner {{if @planners.length 'hasData'}}'> + New Planner </Button>
          </LinkTo>
      {{/if}}
  </div>
  */
  {
    "id": "HZT18h39",
    "block": "[[[11,0],[17,1],[24,0,\"planner\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"list-of-planner-cards\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"            \"],[8,[39,2],null,[[\"@route\",\"@model\"],[\"planners.planner\",[30,3,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,0],[14,0,\"planner-card\"],[12],[1,\"\\n                    \"],[10,0],[14,0,\"planner-details\"],[12],[1,\"\\n                        \"],[10,1],[14,0,\"planner-card--title\"],[12],[1,\"\\n                            \"],[10,1],[15,0,[29,[\"planner-bulletin \",[52,[30,3,[\"isPublished\"]],\"published-planner\",\"unpublished-planner\"]]]],[12],[1,\"\\n                            \"],[13],[1,\"\\n                            \"],[1,[30,3,[\"name\"]]],[1,\"\\n                        \"],[13],[1,\"\\n                        \"],[10,1],[14,0,\"planner-card--team\"],[12],[1,[30,3,[\"teamName\"]]],[1,\" / \"],[1,[30,3,[\"tenantName\"]]],[13],[1,\"\\n                    \"],[13],[1,\"\\n                \"],[13],[1,\"\\n            \"]],[]]]]],[1,\"\\n\"]],[3]],null],[1,\"    \"],[13],[1,\"\\n\"],[41,[28,[37,4],[\"read congress\",[30,4]],null],[[[1,\"        \"],[8,[39,2],[[24,0,\"link-to-new-planner\"]],[[\"@route\",\"@query\"],[\"planners.new\",[28,[37,5],null,[[\"congressId\",\"teamId\",\"tenantId\"],[[30,4,[\"id\"]],[30,5,[\"id\"]],[30,6,[\"id\"]]]]]]],[[\"default\"],[[[[1,\"\\n            \"],[8,[39,6],[[16,0,[29,[\"primary new-planner \",[52,[30,2,[\"length\"]],\"hasData\"]]]]],null,[[\"default\"],[[[[1,\" + New Planner \"]],[]]]]],[1,\"\\n        \"]],[]]]]],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"@planners\",\"planner\",\"@congress\",\"@team\",\"@tenant\"],false,[\"each\",\"-track-array\",\"link-to\",\"if\",\"can\",\"hash\",\"button\"]]",
    "moduleName": "congress-ap-client/components/congress-listing/planner-list.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});