define("congress-ap-client/utils/download-file-using-blob", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = downloadFileUsingBlob;

  function downloadFileUsingBlob(blob, fileName, optionalFileName) {
    if (fileName && fileName.indexOf('filename=') !== -1) {
      let fileNameArray = fileName.split('filename=');
      fileName = fileNameArray[1] && fileNameArray[1].replace(/['"]/g, '') || optionalFileName;
    }

    var hiddenElement = document.createElement('a');
    hiddenElement.href = window.URL.createObjectURL(blob); // Create an object URL for the Blob and dowload it.

    hiddenElement.target = '_blank';
    hiddenElement.download = fileName || optionalFileName; //Provide the name for the CSV file to be downloaded

    hiddenElement.click();
  }
});