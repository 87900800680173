define("congress-ap-client/components/accordion", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='accordion' ...attributes>
      {{yield
          (hash
              item=(component
                  'accordion/item' activeAccordion=@activeAccordion toggleAccordion=@toggleAccordion
              )
          )
      }}
  </div>
  */
  {
    "id": "vCHtnUqb",
    "block": "[[[11,0],[24,0,\"accordion\"],[17,1],[12],[1,\"\\n    \"],[18,4,[[28,[37,1],null,[[\"item\"],[[50,\"accordion/item\",0,null,[[\"activeAccordion\",\"toggleAccordion\"],[[30,2],[30,3]]]]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@activeAccordion\",\"@toggleAccordion\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "congress-ap-client/components/accordion.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});