define("congress-ap-client/components/congress-listing/data-row/date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='congress-date-cell'>
      <div><span class='date-month'>{{moment-format @record.startDate 'MMM'}}</span><span
          class='date-day'
      >{{moment-format @record.startDate 'DD'}}</span></div>
      <div><span class='date-month'>{{moment-format @record.endDate 'MMM'}}</span><span
          class='date-day'
      >{{moment-format @record.endDate 'DD'}}</span></div>
  </div>
  */
  {
    "id": "0pDiS+fS",
    "block": "[[[10,0],[14,0,\"congress-date-cell\"],[12],[1,\"\\n    \"],[10,0],[12],[10,1],[14,0,\"date-month\"],[12],[1,[28,[35,0],[[30,1,[\"startDate\"]],\"MMM\"],null]],[13],[10,1],[14,0,\"date-day\"],[12],[1,[28,[35,0],[[30,1,[\"startDate\"]],\"DD\"],null]],[13],[13],[1,\"\\n    \"],[10,0],[12],[10,1],[14,0,\"date-month\"],[12],[1,[28,[35,0],[[30,1,[\"endDate\"]],\"MMM\"],null]],[13],[10,1],[14,0,\"date-day\"],[12],[1,[28,[35,0],[[30,1,[\"endDate\"]],\"DD\"],null]],[13],[13],[1,\"\\n\"],[13]],[\"@record\"],false,[\"moment-format\"]]",
    "moduleName": "congress-ap-client/components/congress-listing/data-row/date.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});