define("congress-ap-client/components/search-box", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='search-box' ...attributes>
      <Input
          class='textbox search-input'
          placeholder={{@searchPlaceHolder}}
          value={{this.searchText}}
          @enter={{(perform this.initiateSearch this.searchText false)}}
          {{on 'input' (perform this.initiateSearch value='target.value')}}
      />
      <FaIcon 
          @icon='search' 
          class="search-icon" 
          {{on 'click' (perform this.initiateSearch this.searchText false)}} 
      />
  </div>
  */
  {
    "id": "BM1gGYW3",
    "block": "[[[11,0],[24,0,\"search-box\"],[17,1],[12],[1,\"\\n    \"],[8,[39,0],[[24,0,\"textbox search-input\"],[16,\"placeholder\",[30,2]],[16,2,[30,0,[\"searchText\"]]],[4,[38,2],[\"input\",[28,[37,1],[[30,0,[\"initiateSearch\"]]],[[\"value\"],[\"target.value\"]]]],null]],[[\"@enter\"],[[28,[37,1],[[30,0,[\"initiateSearch\"]],[30,0,[\"searchText\"]],false],null]]],null],[1,\"\\n    \"],[8,[39,3],[[24,0,\"search-icon\"],[4,[38,2],[\"click\",[28,[37,1],[[30,0,[\"initiateSearch\"]],[30,0,[\"searchText\"]],false],null]],null]],[[\"@icon\"],[\"search\"]],null],[1,\"\\n\"],[13]],[\"&attrs\",\"@searchPlaceHolder\"],false,[\"input\",\"perform\",\"on\",\"fa-icon\"]]",
    "moduleName": "congress-ap-client/components/search-box.hbs",
    "isStrictMode": false
  });

  let SearchBoxComponent = (_dec = Ember._tracked, _dec2 = (0, _emberConcurrency.task)({
    restartable: true
  }), (_class = class SearchBoxComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "searchText", _descriptor, this);
    }

    *initiateSearch(searchText, delaySearch = true) {
      this.searchText = searchText;

      if (delaySearch) {
        yield (0, _emberConcurrency.timeout)(this.args.timeDelay ? this.args.timeDelay : 300);
      }

      this.args.onSearch && this.args.onSearch(searchText);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "searchText", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.searchText || '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initiateSearch", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initiateSearch"), _class.prototype)), _class));
  _exports.default = SearchBoxComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SearchBoxComponent);
});