define("congress-ap-client/components/sidebar-table/sidebar", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='sidebar' style='width:{{this.width}}'>
      {{yield}}
  </div>
  */
  {
    "id": "2oUyFQnV",
    "block": "[[[10,0],[14,0,\"sidebar\"],[15,5,[29,[\"width:\",[30,0,[\"width\"]]]]],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "congress-ap-client/components/sidebar-table/sidebar.hbs",
    "isStrictMode": false
  });

  class SidebarTableSidebarComponent extends _component.default {
    get width() {
      return this.args.state.showSidebar ? this.args.state.width : '0px';
    }

  }

  _exports.default = SidebarTableSidebarComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SidebarTableSidebarComponent);
});