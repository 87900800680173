define("congress-ap-client/components/tenant/tenant-member-list/slack-notification-cell", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <ToggleButton
      @disabled={{@record.isDeleted}}
      @toggleValue={{@record.notificationEnabled}}
      @onToggle={{this.updateSlackNotification}}
  />
  */
  {
    "id": "IDzwoGKg",
    "block": "[[[8,[39,0],null,[[\"@disabled\",\"@toggleValue\",\"@onToggle\"],[[30,1,[\"isDeleted\"]],[30,1,[\"notificationEnabled\"]],[30,0,[\"updateSlackNotification\"]]]],null]],[\"@record\"],false,[\"toggle-button\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-member-list/slack-notification-cell.hbs",
    "isStrictMode": false
  });

  let TenantTenantMemberListSlackNotificationCellComponent = (_dec = Ember._action, (_class = class TenantTenantMemberListSlackNotificationCellComponent extends _component.default {
    updateSlackNotification() {
      this.args.record.notificationEnabled = this.args.record.notificationEnabled ? 0 : 1;
      this.args.column.modifyUser.perform(this.args.record);
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "updateSlackNotification", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "updateSlackNotification"), _class.prototype)), _class));
  _exports.default = TenantTenantMemberListSlackNotificationCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantMemberListSlackNotificationCellComponent);
});