define("congress-ap-client/components/sessions-data-table/append-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <FileUpload @onChange={{perform @upload @route}} @disabled={{@disabled}}>
      <div class='upload' ...attributes>
          <img src='/images/upload.svg' />
          {{#if @upload.isRunning}}
              Uploading...
          {{else}}
              Add More Sessions
          {{/if}}
      </div>
  </FileUpload>
  */
  {
    "id": "clp6siVA",
    "block": "[[[8,[39,0],null,[[\"@onChange\",\"@disabled\"],[[28,[37,1],[[30,1],[30,2]],null],[30,3]]],[[\"default\"],[[[[1,\"\\n    \"],[11,0],[24,0,\"upload\"],[17,4],[12],[1,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/images/upload.svg\"],[12],[13],[1,\"\\n\"],[41,[30,1,[\"isRunning\"]],[[[1,\"            Uploading...\\n\"]],[]],[[[1,\"            Add More Sessions\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n\"]],[]]]]]],[\"@upload\",\"@route\",\"@disabled\",\"&attrs\"],false,[\"file-upload\",\"perform\",\"if\"]]",
    "moduleName": "congress-ap-client/components/sessions-data-table/append-session.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});