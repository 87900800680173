define("congress-ap-client/components/dropdown", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="zrx-dropdown {{if this.expanded "active"}} {{name}}" {{did-insert this.setupElement}} ...attributes>
      {{#if (has-block "title")}}
          <div class="zrx-dropdown-title">
              {{yield (hash
                  expanded=expanded
                  toggleContent=toggleContent
              ) to="title"}}
          </div>
      {{/if}}
      {{#if (has-block "content")}}
          <div class="zrx-dropdown-content" style="height:{{contentHeight}};">
              {{yield (hash
                  expanded=expanded
                  toggleContent=toggleContent
              ) to="content"}}
          </div>
      {{/if}}
  </div>
  */
  {
    "id": "5lTZzrLm",
    "block": "[[[11,0],[16,0,[29,[\"zrx-dropdown \",[52,[30,0,[\"expanded\"]],\"active\"],\" \",[36,1]]]],[17,1],[4,[38,2],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"        \"],[10,0],[14,0,\"zrx-dropdown-title\"],[12],[1,\"\\n            \"],[18,2,[[28,[37,5],null,[[\"expanded\",\"toggleContent\"],[[33,6],[33,7]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[48,[30,3]],[[[1,\"        \"],[10,0],[14,0,\"zrx-dropdown-content\"],[15,5,[29,[\"height:\",[36,8],\";\"]]],[12],[1,\"\\n            \"],[18,3,[[28,[37,5],null,[[\"expanded\",\"toggleContent\"],[[33,6],[33,7]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"&title\",\"&content\"],false,[\"if\",\"name\",\"did-insert\",\"has-block\",\"yield\",\"hash\",\"expanded\",\"toggleContent\",\"contentHeight\"]]",
    "moduleName": "congress-ap-client/components/dropdown.hbs",
    "isStrictMode": false
  });

  let DropdownComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, _dec5 = Ember._action, (_class = class DropdownComponent extends _component.default {
    get contentHeight() {
      return this.element && this.expanded ? `${this.element.querySelector('.zrx-dropdown-content').scrollHeight}px` : '0px';
    }

    get name() {
      return `dropdown-${Ember.guidFor(this.element)}`;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "element", _descriptor, this);

      _initializerDefineProperty(this, "expanded", _descriptor2, this);

      document.addEventListener('click', this.closeDropdown);
    }

    setupElement(element) {
      this.element = element;
    }

    closeDropdown(event) {
      if (!event.target.closest(`.${this.name}`)) {
        this.expanded = false;
      }
    }

    toggleContent() {
      event.stopPropagation();
      this.expanded = !this.expanded;
    }

    willDestroy() {
      document.removeEventListener('click', this.closeDropdown);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expanded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "closeDropdown", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "closeDropdown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleContent", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleContent"), _class.prototype)), _class));
  _exports.default = DropdownComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, DropdownComponent);
});