define("congress-ap-client/components/tenant/tenant-member-list/email-cell", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='tenant-member-emailId'>
      <div class='short-version' {{did-insert this.setupElement}}>
          {{@record.emailId}}
      </div>
      {{#if this.isEllipsisActive}}
          <div class='long-version'>{{@record.emailId}}</div>
      {{/if}}
  </div>
  */
  {
    "id": "TvVrmkk4",
    "block": "[[[10,0],[14,0,\"tenant-member-emailId\"],[12],[1,\"\\n    \"],[11,0],[24,0,\"short-version\"],[4,[38,0],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n        \"],[1,[30,1,[\"emailId\"]]],[1,\"\\n    \"],[13],[1,\"\\n\"],[41,[30,0,[\"isEllipsisActive\"]],[[[1,\"        \"],[10,0],[14,0,\"long-version\"],[12],[1,[30,1,[\"emailId\"]]],[13],[1,\"\\n\"]],[]],null],[13]],[\"@record\"],false,[\"did-insert\",\"if\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-member-list/email-cell.hbs",
    "isStrictMode": false
  });

  let TenantTenantMemberListEmailCellComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class TenantTenantMemberListEmailCellComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "element", _descriptor, this);
    }

    get isEllipsisActive() {
      return this.args.record.emailId && this.element && this.element.offsetWidth < this.element.scrollWidth;
    }

    setupElement(element) {
      this.element = element;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype)), _class));
  _exports.default = TenantTenantMemberListEmailCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantMemberListEmailCellComponent);
});