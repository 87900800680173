define("congress-ap-client/mirage/factories/tenant", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return `${_faker.default.lorem.word()}`;
    },

    website: null,
    isPinned: 1,
    // activeUsers: Math.floor(Math.random() * 5) + 1,
    activeUsers: 10,
    inactiveUsers: Math.floor(Math.random() * 5) + 1,
    teamsCount: Math.floor(Math.random() * 5) + 1,
    isDemo: 1,

    permissions() {
      let i = 0,
          array = [];

      for (i = 0; i <= _faker.default.datatype.number(4); i++) {
        array.push(_faker.default.random.arrayElement(['planner_create', 'planner_read', 'planner_edit', 'planner_delete']));
      }

      return array;
    },

    withPlanners: (0, _emberCliMirage.trait)({
      afterCreate(tenant, server) {
        server.createList('planner', 5, {
          tenant
        });
      }

    }),
    withTeams: (0, _emberCliMirage.trait)({
      afterCreate(tenant, server) {
        server.createList('team', 5, {
          tenant
        });
      }

    }),

    afterCreate(tenant, server) {
      tenant.website = `www.${tenant.name}.ferma.ai`;
    }

  });

  _exports.default = _default;
});