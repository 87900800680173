define("congress-ap-client/components/session-data-table/date-search", ["exports", "@glimmer/component", "moment-timezone", "congress-ap-client/utils/is-empty"], function (_exports, _component, _momentTimezone, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='date-search ember-text-field emt-column-input-search ember-view'>
      <img src='/images/calendar-search-icon.svg' />
      <EmberFlatpickr
          class='date-search-input'
          placeholder='Date'
          @date={{this.dateString}}
          @onChange={{action this.updateSearchDate}} />
      {{#if this.args.column.filterString}}
          <FaIcon class='close' @icon='times' {{on 'click' (fn this.clear)}} />
      {{/if}}
  </div>
  
  */
  {
    "id": "7VLeJEIh",
    "block": "[[[10,0],[14,0,\"date-search ember-text-field emt-column-input-search ember-view\"],[12],[1,\"\\n    \"],[10,\"img\"],[14,\"src\",\"/images/calendar-search-icon.svg\"],[12],[13],[1,\"\\n    \"],[8,[39,0],[[24,0,\"date-search-input\"],[24,\"placeholder\",\"Date\"]],[[\"@date\",\"@onChange\"],[[30,0,[\"dateString\"]],[28,[37,1],[[30,0],[30,0,[\"updateSearchDate\"]]],null]]],null],[1,\"\\n\"],[41,[30,0,[\"args\",\"column\",\"filterString\"]],[[[1,\"        \"],[8,[39,3],[[24,0,\"close\"],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"clear\"]]],null]],null]],[[\"@icon\"],[\"times\"]],null],[1,\"\\n\"]],[]],null],[13],[1,\"\\n\"]],[],false,[\"ember-flatpickr\",\"action\",\"if\",\"fa-icon\",\"on\",\"fn\"]]",
    "moduleName": "congress-ap-client/components/session-data-table/date-search.hbs",
    "isStrictMode": false
  });

  let SessionDataTableDateSearchComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class SessionDataTableDateSearchComponent extends _component.default {
    get timezone() {
      return this.args.column.timezone;
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "dateString", _descriptor, this);

      this.initDateString();
    }

    initDateString() {
      if (!(0, _isEmpty.default)(this.args.column.filterString) && this.timezone) {
        this.dateString = _momentTimezone.default.utc(this.args.column.filterString).tz(this.timezone).format('YYYY-MM-DDTHH:mm:ss');
      } else {
        this.dateString = this.args.column.filterString;
      }
    }

    updateSearchDate(value, valueInString) {
      this.dateString = valueInString;
      let dateObj = (0, _momentTimezone.default)(value[0]);

      if (this.args.column.propertyName === 'startDate') {
        dateObj.set({
          hour: 0,
          minute: 0,
          second: 0
        });
      } else {
        dateObj.set({
          hour: 23,
          minute: 59,
          second: 59
        });
      }

      dateObj = dateObj.format('YYYY-MM-DDTHH:mm:ss');

      let timezonedDateString = _momentTimezone.default.tz(dateObj, this.timezone).utc().format('YYYY-MM-DDTHH:mm:ss');

      Ember.set(this.args.column, 'filterString', timezonedDateString);
    }

    clear() {
      this.dateString = null;
      Ember.set(this.args.column, 'filterString', null);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "dateString", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "initDateString", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "initDateString"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateSearchDate", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "updateSearchDate"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype)), _class));
  _exports.default = SessionDataTableDateSearchComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SessionDataTableDateSearchComponent);
});