define("congress-ap-client/adapters/tenant-user", ["exports", "congress-ap-client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TenantUserAdapter extends _application.default {
    urlForQuery(query, modelName) {
      let url = `${this.host}/tenantUsers`;

      if (query.tenant) {
        if (query.team) {
          url = `${this.host}/tenants/${query.tenant}/teams/${query.team}/users`;
        } else {
          url = `${this.host}/tenants/${query.tenant}/users`;
        }
      }

      if (query.adapterOptions && query.adapterOptions.appendParams) {
        url += `?${query.adapterOptions.appendParams.join(',')}`;
      }

      return url;
    }

    urlForCreateRecord(modelName, snapshot) {
      let url = `${this.host}/tenantUsers`;

      if (snapshot.adapterOptions && snapshot.adapterOptions.tenant) {
        url = `${this.host}/tenants/${snapshot.adapterOptions.tenant}/user`;
      }

      return url;
    }

    createRecord(store, type, snapshot) {
      let url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      const data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PUT', {
        data
      });
    }

    urlForUpdateRecord(id, modelName, snapshot) {
      let url = `${this.host}/tenantUsers/${id}`;

      if (snapshot.adapterOptions && snapshot.adapterOptions.tenant) {
        url = `${this.host}/tenants/${snapshot.adapterOptions.tenant}/user/${id}`;
      }

      return url;
    }

    updateRecord(store, type, snapshot) {
      let url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      const data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PATCH', {
        data
      });
    }

  }

  _exports.default = TenantUserAdapter;
});