define("congress-ap-client/adapters/tenant", ["exports", "congress-ap-client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TenantAdapter extends _application.default {
    updateRecord(store, type, snapshot) {
      let url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      const data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PATCH', {
        data
      });
    }

  }

  _exports.default = TenantAdapter;
});