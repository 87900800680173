define("congress-ap-client/components/tenant/tenant-member-list/action-cell", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='active-deactivate-user' {{on 'click' (toggle 'showConfirmationDialog' this)}}>
      <img src='/images/{{if this.currentDeletedStatus 'activate-user.svg' 'deactivate-user.svg'}}' />
  </div>
  {{#if this.showConfirmationDialog}}
      {{#modal-dialog
          containerClass='activate-deactivate-confirmation-dialog'
          translucentOverlay=true
          onClose=(toggle 'showConfirmationDialog' this)
      }}
          <div class='title'>
              Are you sure you want to {{if this.currentDeletedStatus 'activate' 'deactivate'}} <span class='name'>{{@record.name}}</span>?
          </div>
          <div class='actions'>
              <Button
                  class='{{if this.currentDeletedStatus 'primary' 'cautious'}}'
                  @loading={{this.updateDeleteStatus.isRunning}}
                  {{on 'click' (pipe (perform this.updateDeleteStatus) (toggle 'showConfirmationDialog' this))}}
              >
                  {{if this.currentDeletedStatus 'Activate' 'Deactivate'}}
              </Button>
              <Button
                  class='secondary'
                  {{on 'click' (toggle 'showConfirmationDialog' this)}}
              >
                  Close
              </Button>
          </div>
      {{/modal-dialog}}
  {{/if}}
  */
  {
    "id": "eni1cSn+",
    "block": "[[[11,0],[24,0,\"active-deactivate-user\"],[4,[38,0],[\"click\",[28,[37,1],[\"showConfirmationDialog\",[30,0]],null]],null],[12],[1,\"\\n    \"],[10,\"img\"],[15,\"src\",[29,[\"/images/\",[52,[30,0,[\"currentDeletedStatus\"]],\"activate-user.svg\",\"deactivate-user.svg\"]]]],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[30,0,[\"showConfirmationDialog\"]],[[[6,[39,3],null,[[\"containerClass\",\"translucentOverlay\",\"onClose\"],[\"activate-deactivate-confirmation-dialog\",true,[28,[37,1],[\"showConfirmationDialog\",[30,0]],null]]],[[\"default\"],[[[[1,\"        \"],[10,0],[14,0,\"title\"],[12],[1,\"\\n            Are you sure you want to \"],[1,[52,[30,0,[\"currentDeletedStatus\"]],\"activate\",\"deactivate\"]],[1,\" \"],[10,1],[14,0,\"name\"],[12],[1,[30,1,[\"name\"]]],[13],[1,\"?\\n        \"],[13],[1,\"\\n        \"],[10,0],[14,0,\"actions\"],[12],[1,\"\\n            \"],[8,[39,4],[[16,0,[29,[[52,[30,0,[\"currentDeletedStatus\"]],\"primary\",\"cautious\"]]]],[4,[38,0],[\"click\",[28,[37,5],[[28,[37,6],[[30,0,[\"updateDeleteStatus\"]]],null],[28,[37,1],[\"showConfirmationDialog\",[30,0]],null]],null]],null]],[[\"@loading\"],[[30,0,[\"updateDeleteStatus\",\"isRunning\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[1,[52,[30,0,[\"currentDeletedStatus\"]],\"Activate\",\"Deactivate\"]],[1,\"\\n            \"]],[]]]]],[1,\"\\n            \"],[8,[39,4],[[24,0,\"secondary\"],[4,[38,0],[\"click\",[28,[37,1],[\"showConfirmationDialog\",[30,0]],null]],null]],null,[[\"default\"],[[[[1,\"\\n                Close\\n            \"]],[]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]]]]]],[]],null]],[\"@record\"],false,[\"on\",\"toggle\",\"if\",\"modal-dialog\",\"button\",\"pipe\",\"perform\"]]",
    "moduleName": "congress-ap-client/components/tenant/tenant-member-list/action-cell.hbs",
    "isStrictMode": false
  });

  let TenantTenantMemberListActionCellComponent = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = (0, _emberConcurrency.task)({
    drop: true
  }), (_class = class TenantTenantMemberListActionCellComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "toast", _descriptor, this);

      _initializerDefineProperty(this, "showConfirmationDialog", _descriptor2, this);

      _initializerDefineProperty(this, "currentDeletedStatus", _descriptor3, this);
    }

    *updateDeleteStatus() {
      try {
        this.args.record.isDeleted = this.currentDeletedStatus ? 0 : 1;
        yield this.args.column.modifyUser.perform(this.args.record);
        this.toast.success(`${this.args.record.name} is ${this.currentDeletedStatus ? 'activated' : 'deactivated'} successfully`);
        this.args.column.refreshMembers();
      } catch (error) {}
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "showConfirmationDialog", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "currentDeletedStatus", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.record.isDeleted ? true : false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateDeleteStatus", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "updateDeleteStatus"), _class.prototype)), _class));
  _exports.default = TenantTenantMemberListActionCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTenantMemberListActionCellComponent);
});