define("congress-ap-client/components/sidebar-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
      class='sidebar-table
          {{if this.state.disable 'disabled'}}
          {{if this.state.showSidebar 'sidebar-visible' 'sidebar-invisible'}}'
      ...attributes
      {{did-update this.updateProperties @showSidebar @disable}}
  >
      {{yield
          (hash
              sidebar=(component 'sidebar-table/sidebar' state=state actions=actions)
              content=(component 'sidebar-table/content' state=state actions=actions)
              sidebarFooter=(component 'sidebar-table/sidebar-footer' state=state actions=actions)
              state=state
              actions=actions
          )
      }}
  </div>
  */
  {
    "id": "VkDrMptS",
    "block": "[[[11,0],[16,0,[29,[\"sidebar-table\\n        \",[52,[30,0,[\"state\",\"disable\"]],\"disabled\"],\"\\n        \",[52,[30,0,[\"state\",\"showSidebar\"]],\"sidebar-visible\",\"sidebar-invisible\"]]]],[17,1],[4,[38,1],[[30,0,[\"updateProperties\"]],[30,2],[30,3]],null],[12],[1,\"\\n    \"],[18,4,[[28,[37,3],null,[[\"sidebar\",\"content\",\"sidebarFooter\",\"state\",\"actions\"],[[50,\"sidebar-table/sidebar\",0,null,[[\"state\",\"actions\"],[[33,5],[33,6]]]],[50,\"sidebar-table/content\",0,null,[[\"state\",\"actions\"],[[33,5],[33,6]]]],[50,\"sidebar-table/sidebar-footer\",0,null,[[\"state\",\"actions\"],[[33,5],[33,6]]]],[33,5],[33,6]]]]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@showSidebar\",\"@disable\",\"&default\"],false,[\"if\",\"did-update\",\"yield\",\"hash\",\"component\",\"state\",\"actions\"]]",
    "moduleName": "congress-ap-client/components/sidebar-table.hbs",
    "isStrictMode": false
  });

  let SidebarTableComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class SidebarTableComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "state", _descriptor, this);

      _defineProperty(this, "actions", {
        toggleSidebar: () => {
          this.state = { ...this.state,
            showSidebar: !this.state.disable && !this.state.showSidebar
          };
        },
        toggleSidebarFooter: (id = null) => {
          this.state = { ...this.state,
            selectedFooter: this.state.selectedFooter === id ? null : id
          };
        }
      });
    }

    updateProperties() {
      setTimeout(() => {
        this.state = { ...this.state,
          showSidebar: this.args.showSidebar ?? false,
          disable: this.args.disable ?? false
        };
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "state", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        disable: this.args.disable ?? false,
        showSidebar: this.args.showSidebar ?? false,
        selectedFooter: this.args.selectedFooter ?? undefined,
        width: this.args.width ?? '30%'
      };
    }
  }), _applyDecoratedDescriptor(_class.prototype, "updateProperties", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "updateProperties"), _class.prototype)), _class));
  _exports.default = SidebarTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SidebarTableComponent);
});