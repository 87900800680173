define("congress-ap-client/components/planners/priority-column", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <span class="session-priority-col--{{@record.priorityClassName}}">{{@record.priorityName}}</span>
  */
  {
    "id": "0yJV+JCb",
    "block": "[[[10,1],[15,0,[29,[\"session-priority-col--\",[30,1,[\"priorityClassName\"]]]]],[12],[1,[30,1,[\"priorityName\"]]],[13]],[\"@record\"],false,[]]",
    "moduleName": "congress-ap-client/components/planners/priority-column.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});