define("congress-ap-client/adapters/annotation", ["exports", "congress-ap-client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AnnotationAdapter extends _application.default {
    urlForQuery(query) {
      return `${this.host}/congresses/${query.congress}/annotations`;
    }

  }

  _exports.default = AnnotationAdapter;
});