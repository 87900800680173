define("congress-ap-client/controllers/planners/new", ["exports", "ember-concurrency", "congress-ap-client/utils/is-empty"], function (_exports, _emberConcurrency, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlannersNewController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._action, _dec16 = Ember._action, _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = Ember._action, (_class = class PlannersNewController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "queryParams", ['congressId', 'teamId', 'tenantId']);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "router", _descriptor2, this);

      _initializerDefineProperty(this, "ajax", _descriptor3, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor4, this);

      _initializerDefineProperty(this, "congressId", _descriptor5, this);

      _initializerDefineProperty(this, "teamId", _descriptor6, this);

      _initializerDefineProperty(this, "tenantId", _descriptor7, this);

      _initializerDefineProperty(this, "plannerTitle", _descriptor8, this);

      _initializerDefineProperty(this, "selectedCongress", _descriptor9, this);

      _initializerDefineProperty(this, "selectedTeamId", _descriptor10, this);

      _initializerDefineProperty(this, "selectedTenantId", _descriptor11, this);

      _initializerDefineProperty(this, "authorizedCongress", _descriptor12, this);

      _initializerDefineProperty(this, "authorizedTenants", _descriptor13, this);

      _initializerDefineProperty(this, "isSaving", _descriptor14, this);
    }

    get authorizedCongressId() {
      if (this.congressId) {
        let isAuthorized = false;
        let categories = Object.keys(this.model.congresses.data);

        for (let i = 0; i < categories.length; i++) {
          isAuthorized = this.model.congresses.data[categories[i]].find(authorizedCongress => +authorizedCongress.id === +this.congressId);

          if (isAuthorized) {
            return this.congressId;
          }
        }
      }

      return null;
    }

    get hasDirtyAttributes() {
      return this.selectedCongress || this.selectedTeamId || this.selectedTenantId || this.plannerTitle;
    }

    get mandatoryAttributes() {
      return this.selectedCongress && this.selectedTeamId && this.selectedTenantId;
    }

    get selectedTenantName() {
      return this.authorizedTenants.find(tenant => {
        return tenant.id === this.selectedTenantId;
      }).name;
    }

    initializeValues() {
      this.setSelectedCongress.perform(this.authorizedCongressId);
      this.authorizedCongress = this.model.congresses.data;
      this.authorizedTenants = this.model.tenants.data;
      this.setSelectedTeam(this.tenantId, this.teamId);
    }

    *setSelectedCongress(id) {
      if (id) {
        try {
          yield (0, _emberConcurrency.timeout)(0);
          this.selectedCongress = yield this.store.findRecord('congress', id);
          return;
        } catch (error) {
          this.errorHandler.notifyError(error);
        }
      }

      this.selectedCongress = null;
    }

    setSelectedTeam(tenantId, teamId) {
      this.selectedTenantId = tenantId;
      this.selectedTeamId = teamId;
    }

    *onCongressSearch(search) {
      try {
        let congress = yield this.ajax.request(`planners/congresses?name=${search}`, {
          type: 'GET'
        });
        this.authorizedCongress = congress.data;
      } catch (error) {
        this.authorizedCongress = this.model.congress.data;
        this.errorHandler.notifyError(error);
      }
    }

    *onTeamSearch(search) {
      try {
        let tenants = yield this.ajax.request(`planners/tenants?name=${search}`, {
          type: 'GET'
        });
        this.authorizedTenants = tenants.data;
      } catch (error) {
        this.authorizedTenants = this.model.tenants.data;
        this.errorHandler.notifyError(error);
      }
    }

    async submit() {
      try {
        this.isSaving = true;
        let data = {
          congressId: +this.selectedCongress.id,
          tenantId: this.selectedTenantId,
          teamId: this.selectedTeamId
        };

        if (!(0, _isEmpty.default)(this.plannerTitle)) {
          data = { ...data,
            name: this.plannerTitle
          };
        }

        let planner = await this.ajax.request('planners', {
          type: 'PUT',
          data: JSON.stringify({ ...data
          })
        });
        this.clearValues();
        this.router.transitionTo('planners.planner', planner.data.id);
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.isSaving = false;
      }
    }

    clearValues() {
      this.congressId = null;
      this.teamId = null;
      this.tenantId = null;
      this.plannerTitle = null; // TODO: Remove after tenant 

      this.selectedTenantId = null;
      this.selectedTeamId = null;
    }

    cancel() {
      window.history.back();
      this.clearValues();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "congressId", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "teamId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "tenantId", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "plannerTitle", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "selectedCongress", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "selectedTeamId", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "selectedTenantId", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "authorizedCongress", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "authorizedTenants", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "isSaving", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "initializeValues", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "initializeValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedCongress", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedCongress"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setSelectedTeam", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "setSelectedTeam"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCongressSearch", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "onCongressSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTeamSearch", [_emberConcurrency.task], Object.getOwnPropertyDescriptor(_class.prototype, "onTeamSearch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearValues", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "clearValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancel", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "cancel"), _class.prototype)), _class));
  _exports.default = PlannersNewController;
});