define("congress-ap-client/components/button-select/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='bs-button {{if (eq @selectedButton @value) 'active'}}' {{action @onChange @value}}>
      {{yield}}
  </div>
  */
  {
    "id": "OWSfmBxQ",
    "block": "[[[11,0],[16,0,[29,[\"bs-button \",[52,[28,[37,1],[[30,1],[30,2]],null],\"active\"]]]],[4,[38,2],[[30,0],[30,3],[30,2]],null],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n\"],[13]],[\"@selectedButton\",\"@value\",\"@onChange\",\"&default\"],false,[\"if\",\"eq\",\"action\",\"yield\"]]",
    "moduleName": "congress-ap-client/components/button-select/button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});