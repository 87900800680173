define("congress-ap-client/components/tenant/team-list/member-list/role-cell", ["exports", "@glimmer/component", "ember-concurrency"], function (_exports, _component, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if (not this.canEdit)}}
      {{this.role.name}}
  {{else}}
      <Dropdown>
          <:title as |data|>
              <div class='team-user-role-cell' {{on 'click' (fn data.toggleContent)}}>
                  {{this.role.name}}
              </div>
          </:title>
          <:content as |data|>
              {{#each this.rolesList as |role|}}
                  {{#if (eq this.role.id role.id)}}
                      <li class='role-name active' {{on 'click' (fn data.toggleContent)}}>{{role.name}}</li>
                  {{else}}
                      <li
                          class='role-name'
                          {{on 'click' (pipe (fn data.toggleContent) (perform this.updateRole role))}}
                      >{{role.name}}</li>
                  {{/if}}
              {{else}}
                  <div class='no-search-content'>
                      No roles found
                  </div>
              {{/each}}
          </:content>
      </Dropdown>
  {{/if}}
  */
  {
    "id": "usmSDZr+",
    "block": "[[[41,[28,[37,1],[[30,0,[\"canEdit\"]]],null],[[[1,\"    \"],[1,[30,0,[\"role\",\"name\"]]],[1,\"\\n\"]],[]],[[[1,\"    \"],[8,[39,2],null,null,[[\"title\",\"content\"],[[[[1,\"\\n            \"],[11,0],[24,0,\"team-user-role-cell\"],[4,[38,3],[\"click\",[28,[37,4],[[30,1,[\"toggleContent\"]]],null]],null],[12],[1,\"\\n                \"],[1,[30,0,[\"role\",\"name\"]]],[1,\"\\n            \"],[13],[1,\"\\n        \"]],[1]],[[[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"rolesList\"]]],null]],null],null,[[[41,[28,[37,7],[[30,0,[\"role\",\"id\"]],[30,3,[\"id\"]]],null],[[[1,\"                    \"],[11,\"li\"],[24,0,\"role-name active\"],[4,[38,3],[\"click\",[28,[37,4],[[30,2,[\"toggleContent\"]]],null]],null],[12],[1,[30,3,[\"name\"]]],[13],[1,\"\\n\"]],[]],[[[1,\"                    \"],[11,\"li\"],[24,0,\"role-name\"],[4,[38,3],[\"click\",[28,[37,8],[[28,[37,4],[[30,2,[\"toggleContent\"]]],null],[28,[37,9],[[30,0,[\"updateRole\"]],[30,3]],null]],null]],null],[12],[1,[30,3,[\"name\"]]],[13],[1,\"\\n\"]],[]]]],[3]],[[[1,\"                \"],[10,0],[14,0,\"no-search-content\"],[12],[1,\"\\n                    No roles found\\n                \"],[13],[1,\"\\n\"]],[]]],[1,\"        \"]],[2]]]]],[1,\"\\n\"]],[]]]],[\"data\",\"data\",\"role\"],false,[\"if\",\"not\",\"dropdown\",\"on\",\"fn\",\"each\",\"-track-array\",\"eq\",\"pipe\",\"perform\"]]",
    "moduleName": "congress-ap-client/components/tenant/team-list/member-list/role-cell.hbs",
    "isStrictMode": false
  });

  let TenantTeamListMemberListRoleCellComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = class TenantTeamListMemberListRoleCellComponent extends _component.default {
    get canEdit() {
      var _this$roles$roles$fin;

      return ((_this$roles$roles$fin = this.roles.roles.find(role => +role.id === +this.args.record.roleId)) === null || _this$roles$roles$fin === void 0 ? void 0 : _this$roles$roles$fin.level) > 1;
    }

    get rolesList() {
      return this.roles.roles.filter(role => +role.level > 1);
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "errorHandler", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "roles", _descriptor3, this);

      _initializerDefineProperty(this, "role", _descriptor4, this);

      this.setRole();
    }

    *updateRole(role) {
      try {
        yield this.ajax.request(`tenants/${this.args.column.tenantId}/teams/${this.args.column.teamId}/user/${this.args.record.id}`, {
          type: 'PATCH',
          data: JSON.stringify({
            role_id: role.id
          })
        });
        this.role = role;
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

    setRole() {
      let role = this.roles.roles.find(role => +role.id === +this.args.record.roleId);

      if (role) {
        this.role = { ...role
        };
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "roles", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "role", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "updateRole", [_emberConcurrency.restartableTask], Object.getOwnPropertyDescriptor(_class.prototype, "updateRole"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setRole", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "setRole"), _class.prototype)), _class));
  _exports.default = TenantTeamListMemberListRoleCellComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, TenantTeamListMemberListRoleCellComponent);
});