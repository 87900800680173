define("congress-ap-client/components/bread-crumbs", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="standard-bread-crumbs" ...attributes>
      {{#if (has-block)}}
          {{yield routeHierarchy}}
      {{else}}
          {{#each routeHierarchy as |route|}}
              {{#if (not route.isTail)}}
                  <LinkTo class="bread-crumb" @route={{route.path}}>
                      {{route.title}}
                  </LinkTo>
                  <span class="bread-crumb-separator">/</span>
              {{else}}
                  <span class="bread-crumb-tail">{{route.title}}</span>
              {{/if}}
          {{/each}}
      {{/if}}
  </div>
  
  */
  {
    "id": "cYHa27Tx",
    "block": "[[[11,0],[24,0,\"standard-bread-crumbs\"],[17,1],[12],[1,\"\\n\"],[41,[48,[30,3]],[[[1,\"        \"],[18,3,[[33,3]]],[1,\"\\n\"]],[]],[[[42,[28,[37,5],[[28,[37,5],[[33,3]],null]],null],null,[[[41,[28,[37,6],[[30,2,[\"isTail\"]]],null],[[[1,\"                \"],[8,[39,7],[[24,0,\"bread-crumb\"]],[[\"@route\"],[[30,2,[\"path\"]]]],[[\"default\"],[[[[1,\"\\n                    \"],[1,[30,2,[\"title\"]]],[1,\"\\n                \"]],[]]]]],[1,\"\\n                \"],[10,1],[14,0,\"bread-crumb-separator\"],[12],[1,\"/\"],[13],[1,\"\\n\"]],[]],[[[1,\"                \"],[10,1],[14,0,\"bread-crumb-tail\"],[12],[1,[30,2,[\"title\"]]],[13],[1,\"\\n\"]],[]]]],[2]],null]],[]]],[13],[1,\"\\n\"]],[\"&attrs\",\"route\",\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"routeHierarchy\",\"each\",\"-track-array\",\"not\",\"link-to\"]]",
    "moduleName": "congress-ap-client/components/bread-crumbs.hbs",
    "isStrictMode": false
  });
  /**
   * Automatic breadcrumbs using current route structure
   * Usage:
   *  Configure breadCrumb property at each route
   *  breadCrumb: {
   *      title: String    // Configure title of the route to be displayed, by default it use route name.
   *  }
   */


  let BreadCrumbsComponent = (_dec = Ember.inject.service, (_class = class BreadCrumbsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);
    }

    // Extract breadcrumb from current route hierarchy
    get routeHierarchy() {
      let routeNames = this.router.currentRouteName.split('.');
      let filteredRouteNames = this.filterIndexAndLoadingRoutes(routeNames);
      return this.lookupBreadCrumb(routeNames, filteredRouteNames) || [];
    }

    filterIndexAndLoadingRoutes(routeNames) {
      return routeNames.filter(name => !(name === 'index' || name === 'loading'));
    }

    guessRoutePath(routeNames, name, index) {
      let routes = routeNames.slice(0, index + 1);
      return routes.join('.');
    }

    lookupRoute(routeName) {
      return Ember.getOwner(this).lookup(`route:${routeName}`);
    }

    lookupBreadCrumb(routeNames, filteredRouteNames) {
      let pathLength = filteredRouteNames.length;
      let breadCrumbs = [];
      filteredRouteNames.map((name, index) => {
        let path = this.guessRoutePath(routeNames, name, index);
        let route = this.lookupRoute(path);
        let isHead = index === 0;
        let isTail = index === pathLength - 1;
        let breadCrumb = Ember.getWithDefault(route, 'breadCrumb', {
          title: Ember.String.classify(name)
        });
        breadCrumb = { ...breadCrumb,
          path,
          isHead,
          isTail
        };
        breadCrumbs.pushObject(breadCrumb);
      });
      return breadCrumbs.filter(breadCrumb => typeof breadCrumb !== 'undefined');
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = BreadCrumbsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, BreadCrumbsComponent);
});