define("congress-ap-client/mirage/factories/user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    firstName() {
      return `${_faker.default.name.firstName()}`;
    },

    lastName() {
      return `${_faker.default.name.lastName()}`;
    },

    name() {
      return `${this.firstName} ${this.lastName}`;
    },

    email() {
      return `${this.firstName.toLowerCase()}.${this.lastName.toLowerCase()}@zoomrx.com`;
    }

  });

  _exports.default = _default;
});