define("congress-ap-client/templates/planners/planner-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DRLuc2EB",
    "block": "[[[10,0],[14,0,\"page-loader\"],[14,5,\"display: block;\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"page-spinner\"],[12],[13],[1,\"\\n\"],[13]],[],false,[]]",
    "moduleName": "congress-ap-client/templates/planners/planner-loading.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});