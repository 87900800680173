define("congress-ap-client/components/segment-button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='segment-buttons' ...attributes>
      {{#each @segments as |segment|}}
          <div
              class='button segment-text {{if (eq segment.value this.selectedSegment) 'active'}}'
              {{on 'click' (fn this.onSegmentChange segment.value)}}
          >
              {{segment.name}}
          </div>
      {{/each}}
  </div>
  */
  {
    "id": "qBO9srqw",
    "block": "[[[11,0],[24,0,\"segment-buttons\"],[17,1],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,2]],null]],null],null,[[[1,\"        \"],[11,0],[16,0,[29,[\"button segment-text \",[52,[28,[37,3],[[30,3,[\"value\"]],[30,0,[\"selectedSegment\"]]],null],\"active\"]]]],[4,[38,4],[\"click\",[28,[37,5],[[30,0,[\"onSegmentChange\"]],[30,3,[\"value\"]]],null]],null],[12],[1,\"\\n            \"],[1,[30,3,[\"name\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[3]],null],[13]],[\"&attrs\",\"@segments\",\"segment\"],false,[\"each\",\"-track-array\",\"if\",\"eq\",\"on\",\"fn\"]]",
    "moduleName": "congress-ap-client/components/segment-button.hbs",
    "isStrictMode": false
  });

  let SegmentButtonComponent = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = class SegmentButtonComponent extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "selectedSegment", _descriptor, this);

      this.selectedSegment = this.args.default;
    } // Value needed from parent component: -
    // default -> default segment value
    // segments -> the name and value of all segments
    // ex:- segments = [{name: 'All', value: 'All'},{.....}]
    // onBeforeSelect (optional) -> the function called before chaanging segment value
    // onSelect(optional) -> the function called after chaanging segment value


    async onSegmentChange(clickedSegment) {
      if (this.args.onBeforeSelect) {
        let beforeApproved = this.args.onBeforeSelect(clickedSegment);

        if (beforeApproved instanceof Promise) {
          try {
            await beforeApproved;
          } catch (err) {
            console.log(err);
            return;
          }

          if (!beforeApproved) {
            return;
          }
        }
      }

      let oldSegment = this.selectedSegment;
      this.selectedSegment = clickedSegment;

      if (this.args.onSelect) {
        let approved = this.args.onSelect(clickedSegment);

        try {
          await approved;
        } catch (err) {
          console.log(err);
          this.selectedSegment = oldSegment;
          return;
        }

        if (!approved) {
          this.selectedSegment = oldSegment;
          return;
        }
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "selectedSegment", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return '';
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onSegmentChange", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "onSegmentChange"), _class.prototype)), _class));
  _exports.default = SegmentButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SegmentButtonComponent);
});