define("congress-ap-client/controllers/planners/planner", ["exports", "ember-concurrency", "congress-ap-client/utils/is-empty", "congress-ap-client/utils/download-file-using-blob"], function (_exports, _emberConcurrency, _isEmpty, _downloadFileUsingBlob) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _dec20, _dec21, _dec22, _dec23, _dec24, _dec25, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let PlannersPlannerController = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember.inject.service, _dec5 = Ember.inject.service, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec17 = Ember._action, _dec18 = Ember._action, _dec19 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec20 = Ember._action, _dec21 = Ember._action, _dec22 = Ember._action, _dec23 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec24 = (0, _emberConcurrency.task)({
    drop: true
  }), _dec25 = Ember._action, (_class = class PlannersPlannerController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "errorHandler", _descriptor, this);

      _initializerDefineProperty(this, "ajax", _descriptor2, this);

      _initializerDefineProperty(this, "router", _descriptor3, this);

      _initializerDefineProperty(this, "plannerPriority", _descriptor4, this);

      _initializerDefineProperty(this, "retainFilters", _descriptor5, this);

      _initializerDefineProperty(this, "routedFromCongress", _descriptor6, this);

      _initializerDefineProperty(this, "isPublished", _descriptor7, this);

      _initializerDefineProperty(this, "showDeletePlannerDialog", _descriptor8, this);

      _initializerDefineProperty(this, "reenteredPlannerTitle", _descriptor9, this);

      _initializerDefineProperty(this, "priorityCountSummary", _descriptor10, this);

      _initializerDefineProperty(this, "isPriorityCountLoading", _descriptor11, this);

      _initializerDefineProperty(this, "arePrioritiesUnassigned", _descriptor12, this);

      _initializerDefineProperty(this, "sessionTableId", _descriptor13, this);

      _initializerDefineProperty(this, "sessionTableData", _descriptor14, this);

      _initializerDefineProperty(this, "sessionTableIsLoading", _descriptor15, this);
    }

    get teamName() {
      var _this$model$get;

      let team = ((_this$model$get = this.model.get('tenant.teams')) === null || _this$model$get === void 0 ? void 0 : _this$model$get.find(team => +team.id === +this.model.teamId)) || null;

      if (team) {
        return ` - ${team.name}`;
      }

      return null;
    }

    *publishPlanner() {
      try {
        yield this.ajax.request(`planners/${this.model.id}/publish`, {
          type: 'POST'
        });
        this.isPublished = true;
      } catch (error) {
        this.isPublished = false;
        this.errorHandler.notifyError(error);
      }
    }

    async onCalendarToggle(event) {
      try {
        event.preventDefault();
        await this.ajax.request(`planners/${this.model.id}`, {
          type: 'PATCH',
          data: {
            calendarEnabled: +!this.model.calendarEnabled
          }
        });
      } catch (error) {
        this.model.rollbackAttributes();
        this.errorHandler.notifyError(error);
      }
    }

    async changeTitle() {
      try {
        await this.ajax.request(`planners/${this.model.id}`, {
          type: 'PATCH',
          data: {
            name: this.model.name
          }
        });
      } catch (error) {
        this.model.rollbackAttributes();
        this.errorHandler.notifyError(error);
      }
    }

    *deletePlanner() {
      try {
        this.model.deleteRecord();
        yield this.model.save();
        this.router.transitionTo('congress-view');
      } catch (error) {
        this.model.rollbackAttributes();
        this.errorHandler.notifyError(error);
      }
    }

    initializeValues() {
      this.isPublished = this.model.isPublished;
    }

    focus(element) {
      element.focus();
    }

    async fetchPriorityCountSummary() {
      try {
        this.isPriorityCountLoading = true;
        await this.plannerPriority.fetchPriorities();
        this.priorityCountSummary = this.plannerPriority.listOfPlannerPrioritiesByRank.slice();
        let priorityCountSummaryResponse = await this.ajax.request(`planners/${this.model.id}/count`);
        this.priorityCountSummary = this.priorityCountSummary.map(priorityCountObject => {
          if (priorityCountObject['id'] == 0 && priorityCountSummaryResponse[priorityCountObject['id']] != this.model.congress.get('totalSessions')) {
            this.arePrioritiesUnassigned = false;
          }

          return { ...priorityCountObject,
            data: priorityCountSummaryResponse[priorityCountObject['id']] || 0
          };
        });
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.isPriorityCountLoading = false;
      }
    }

    async fetchSessions() {
      try {
        this.sessionTableIsLoading = true;
        this.sessionTableData = await this.store.query('session', {
          planner: this.model.id,
          reqType: 'POST',
          search: this.retainFilters.getAllFiltersFor(this.sessionTableId),
          sort: this.retainFilters.getAllSortsFor(this.sessionTableId),
          limit: 10,
          offset: 0
        });
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.sessionTableIsLoading = false;
      }
    }

    *uploadFile(files) {
      try {
        if (!files.length) {
          return;
        }

        let formData = new FormData();
        formData.append('file', files[0]);
        yield this.ajax.request(`planners/${this.model.id}/priorities`, {
          type: 'PUT',
          data: formData,
          processData: false,
          contentType: false
        });
        this.fetchSessions();
        this.fetchPriorityCountSummary();
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

    *downloadFile(route) {
      try {
        let response = yield fetch(`${this.ajax.host}planners/${this.model.id}/download?download_type=${route}`, {
          headers: this.ajax.headers
        }); // fetch() won’t reject on HTTP error status even if the response is an HTTP 404 or 500

        if (response.ok) {
          let blob = yield response.blob(); // Retrieve Response Stream as Blob

          let fileName = response.headers.get('content-disposition'); // Get file name from header

          (0, _downloadFileUsingBlob.default)(blob, fileName, 'sessions.csv');
          return;
        }

        throw {
          errors: [{
            status: response.status,
            title: response.statusText || 'Oops! Something went wrong. Please try again.'
          }]
        };
      } catch (error) {
        this.errorHandler.notifyError(error);
      }
    }

    redirectToTenant(tenantId, teamId) {
      this.router.transitionTo('tenants.tenant', tenantId, {
        queryParams: {
          teamId: teamId
        }
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "router", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "plannerPriority", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "retainFilters", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "routedFromCongress", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isPublished", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "showDeletePlannerDialog", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "reenteredPlannerTitle", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "priorityCountSummary", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "isPriorityCountLoading", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "arePrioritiesUnassigned", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "sessionTableId", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'plannerSessions';
    }
  }), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "sessionTableData", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "sessionTableIsLoading", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return true;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "publishPlanner", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "publishPlanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onCalendarToggle", [_dec17], Object.getOwnPropertyDescriptor(_class.prototype, "onCalendarToggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "changeTitle", [_dec18], Object.getOwnPropertyDescriptor(_class.prototype, "changeTitle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deletePlanner", [_dec19], Object.getOwnPropertyDescriptor(_class.prototype, "deletePlanner"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "initializeValues", [_dec20], Object.getOwnPropertyDescriptor(_class.prototype, "initializeValues"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchPriorityCountSummary", [_dec21], Object.getOwnPropertyDescriptor(_class.prototype, "fetchPriorityCountSummary"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "fetchSessions", [_dec22], Object.getOwnPropertyDescriptor(_class.prototype, "fetchSessions"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "uploadFile", [_dec23], Object.getOwnPropertyDescriptor(_class.prototype, "uploadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "downloadFile", [_dec24], Object.getOwnPropertyDescriptor(_class.prototype, "downloadFile"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "redirectToTenant", [_dec25], Object.getOwnPropertyDescriptor(_class.prototype, "redirectToTenant"), _class.prototype)), _class));
  _exports.default = PlannersPlannerController;
});