define("congress-ap-client/components/congress-listing/data-row/status", ["exports", "@glimmer/component", "congress-ap-client/utils/prepend-url-scheme"], function (_exports, _component, _prependUrlScheme) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="congress-status-actions">
      <div class='congress-status'>{{@record.status}}</div>
      <div class='row-options'>
          <div class='button congress-option' {{on 'click' (fn this.viewWebsite @record.website)}}>
              <img src='/images/globe-white.svg' />
              View Website</div>
          {{#if (can 'read congress' @record)}}
              <LinkTo @route='congress-view.congress-details' @model={{@record.id}} class='button congress-option'>
                  <img src='/images/redirect.svg' />    
                  Open Congress</LinkTo>
          {{/if}}
      </div>
  </div>
  
  
  */
  {
    "id": "i5A4vVAA",
    "block": "[[[10,0],[14,0,\"congress-status-actions\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"congress-status\"],[12],[1,[30,1,[\"status\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"row-options\"],[12],[1,\"\\n        \"],[11,0],[24,0,\"button congress-option\"],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"viewWebsite\"]],[30,1,[\"website\"]]],null]],null],[12],[1,\"\\n            \"],[10,\"img\"],[14,\"src\",\"/images/globe-white.svg\"],[12],[13],[1,\"\\n            View Website\"],[13],[1,\"\\n\"],[41,[28,[37,3],[\"read congress\",[30,1]],null],[[[1,\"            \"],[8,[39,4],[[24,0,\"button congress-option\"]],[[\"@route\",\"@model\"],[\"congress-view.congress-details\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"\\n                \"],[10,\"img\"],[14,\"src\",\"/images/redirect.svg\"],[12],[13],[1,\"    \\n                Open Congress\"]],[]]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\\n\"]],[\"@record\"],false,[\"on\",\"fn\",\"if\",\"can\",\"link-to\"]]",
    "moduleName": "congress-ap-client/components/congress-listing/data-row/status.hbs",
    "isStrictMode": false
  });

  let CongressListingDataRowStatusComponent = (_dec = Ember._action, (_class = class CongressListingDataRowStatusComponent extends _component.default {
    viewWebsite(url) {
      event.stopPropagation();
      window.open((0, _prependUrlScheme.default)(url), '_blank');
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "viewWebsite", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "viewWebsite"), _class.prototype)), _class));
  _exports.default = CongressListingDataRowStatusComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CongressListingDataRowStatusComponent);
});