define("congress-ap-client/helpers/stringify", ["exports", "ember-models-table/helpers/stringify"], function (_exports, _stringify) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _stringify.default;
    }
  });
  Object.defineProperty(_exports, "stringify", {
    enumerable: true,
    get: function () {
      return _stringify.stringify;
    }
  });
});