define("congress-ap-client/components/button-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='button-select'>
      {{yield (hash button=(component 'button-select/button' selectedButton=@value onChange=@onChange))}}
  </div>
  */
  {
    "id": "+wWF0tEn",
    "block": "[[[10,0],[14,0,\"button-select\"],[12],[1,\"\\n    \"],[18,3,[[28,[37,1],null,[[\"button\"],[[50,\"button-select/button\",0,null,[[\"selectedButton\",\"onChange\"],[[30,1],[30,2]]]]]]]]],[1,\"\\n\"],[13]],[\"@value\",\"@onChange\",\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "congress-ap-client/components/button-select.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});