define("congress-ap-client/components/expansion-panel", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="expansion-panel {{if this.expanded "active"}}" {{did-insert this.setupElement}} ...attributes>
      {{#if (has-block "collapse")}}
          <div class="expansion-panel-title">
              {{yield (hash
                  expanded=expanded
                  toggleContent=toggleContent
              ) to="collapse"}}
          </div>
      {{/if}}
      {{#if (has-block "expand")}}
          <div class="expansion-panel-content" style="height:{{this.contentHeight}};">
              {{yield (hash
                  expanded=expanded
                  toggleContent=toggleContent
              ) to="expand"}}
          </div>
      {{/if}}
  </div>
  */
  {
    "id": "KWlpuJ5K",
    "block": "[[[11,0],[16,0,[29,[\"expansion-panel \",[52,[30,0,[\"expanded\"]],\"active\"]]]],[17,1],[4,[38,1],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n\"],[41,[48,[30,2]],[[[1,\"        \"],[10,0],[14,0,\"expansion-panel-title\"],[12],[1,\"\\n            \"],[18,2,[[28,[37,4],null,[[\"expanded\",\"toggleContent\"],[[33,5],[33,6]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[41,[48,[30,3]],[[[1,\"        \"],[10,0],[14,0,\"expansion-panel-content\"],[15,5,[29,[\"height:\",[30,0,[\"contentHeight\"]],\";\"]]],[12],[1,\"\\n            \"],[18,3,[[28,[37,4],null,[[\"expanded\",\"toggleContent\"],[[33,5],[33,6]]]]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[13]],[\"&attrs\",\"&collapse\",\"&expand\"],false,[\"if\",\"did-insert\",\"has-block\",\"yield\",\"hash\",\"expanded\",\"toggleContent\"]]",
    "moduleName": "congress-ap-client/components/expansion-panel.hbs",
    "isStrictMode": false
  });

  let ExpansionPanelComponent = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._action, _dec4 = Ember._action, (_class = class ExpansionPanelComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "element", _descriptor, this);

      _initializerDefineProperty(this, "expanded", _descriptor2, this);
    }

    get contentHeight() {
      return this.element && this.expanded ? this.args.contentHeight || `${this.element.querySelector('.expansion-panel-content').scrollHeight}px` : '0px';
    }

    setupElement(element) {
      this.element = element;
    }

    toggleContent() {
      this.expanded = !this.expanded;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "element", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "expanded", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleContent", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "toggleContent"), _class.prototype)), _class));
  _exports.default = ExpansionPanelComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExpansionPanelComponent);
});