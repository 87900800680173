define("congress-ap-client/adapters/planner", ["exports", "congress-ap-client/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class PlannerAdapter extends _application.default {
    createRecord(store, type, snapshot) {
      let url = this.buildURL(type.modelName, null, snapshot, 'createRecord');
      const data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PUT', {
        data
      });
    }

    updateRecord(store, type, snapshot) {
      let url = this.buildURL(type.modelName, snapshot.id, snapshot, 'updateRecord');
      const data = {};
      store.serializerFor(type.modelName).serializeIntoHash(data, type, snapshot);
      return this.ajax(url, 'PATCH', {
        data
      });
    }

    urlForFindRecord(id, modelName, snapshot) {
      let url = super.urlForFindRecord(...arguments);

      if (snapshot.adapterOptions && snapshot.adapterOptions.include) {
        snapshot.adapterOptions.include.split(',').forEach((include, index) => {
          if (index === 0) {
            url = `${url}?include=${include}`;
          } else {
            url = `${url}&include=${include}`;
          }
        });
      }

      return url;
    }

    urlForQuery(query, modelName) {
      if (query.adapterOptions && query.adapterOptions.tenantId && query.adapterOptions.teamId) {
        return `${this.host}/tenants/${query.adapterOptions.tenantId}/teams/${query.adapterOptions.teamId}/planners`;
      }

      return super.urlForQuery(...arguments);
    }

  }

  _exports.default = PlannerAdapter;
});