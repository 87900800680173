define("congress-ap-client/mirage/factories/tenant-user", ["exports", "ember-cli-mirage", "faker"], function (_exports, _emberCliMirage, _faker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberCliMirage.Factory.extend({
    name() {
      return `${_faker.default.name.firstName()} ${_faker.default.name.lastName()}`;
    },

    emailId() {
      return `${this.name.toLowerCase().replace(' ', '')}@zoomrx.com`;
    },

    notificationEnabled: Math.floor(Math.random() * 2),
    internalUser: Math.floor(Math.random() * 2),
    tenantAdmin: Math.floor(Math.random() * 2),
    isDeleted: 0,
    roleId: 4,
    role: 'User',
    withTeams: (0, _emberCliMirage.trait)({
      afterCreate(tenantUser, server) {
        server.createList('team', 3, {
          tenantUsers: [tenantUser]
        });
      }

    }),
    withTenant: (0, _emberCliMirage.trait)({
      afterCreate(tenantUser, server) {
        server.createList('tenant', {
          tenantUser
        });
      }

    })
  });

  _exports.default = _default;
});