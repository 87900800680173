define("congress-ap-client/components/congress-listing/data-row", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='{{if (and this.isVisible (not-eq @record.plannerCount 0)) 'row-expanded'}}' {{did-insert this.setupElement}}>
      {{#let (component @body.Row record=@record index=@index) as |Row|}}
          <div class='row-container'>
              <Row class='congress-row-container congress-data-row' onClick={{if (and (can 'read congress' @record) (not-eq @record.plannerCount 0)) this.expandRow}} />
              <div class='congress-row-separator {{if (not this.isVisible) 'show'}}'/>
          </div>
      {{/let}}
      <div class='line'></div>
      <CongressListing::PlannerList @planners={{@record.planners}} @congress={{@record}} class="view-planners" style={{this.plannerHeight}}/>
  </div>
  */
  {
    "id": "lAA9fJDq",
    "block": "[[[11,0],[16,0,[29,[[52,[28,[37,1],[[30,0,[\"isVisible\"]],[28,[37,2],[[30,1,[\"plannerCount\"]],0],null]],null],\"row-expanded\"]]]],[4,[38,3],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n\"],[44,[[50,[30,2,[\"Row\"]],0,null,[[\"record\",\"index\"],[[30,1],[30,3]]]]],[[[1,\"        \"],[10,0],[14,0,\"row-container\"],[12],[1,\"\\n            \"],[8,[30,4],[[24,0,\"congress-row-container congress-data-row\"],[16,\"onClick\",[52,[28,[37,1],[[28,[37,6],[\"read congress\",[30,1]],null],[28,[37,2],[[30,1,[\"plannerCount\"]],0],null]],null],[30,0,[\"expandRow\"]]]]],null,null],[1,\"\\n            \"],[10,0],[15,0,[29,[\"congress-row-separator \",[52,[28,[37,7],[[30,0,[\"isVisible\"]]],null],\"show\"]]]],[12],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[4]]],[1,\"    \"],[10,0],[14,0,\"line\"],[12],[13],[1,\"\\n    \"],[8,[39,8],[[24,0,\"view-planners\"],[16,5,[30,0,[\"plannerHeight\"]]]],[[\"@planners\",\"@congress\"],[[30,1,[\"planners\"]],[30,1]]],null],[1,\"\\n\"],[13]],[\"@record\",\"@body\",\"@index\",\"Row\"],false,[\"if\",\"and\",\"not-eq\",\"did-insert\",\"let\",\"component\",\"can\",\"not\",\"congress-listing/planner-list\"]]",
    "moduleName": "congress-ap-client/components/congress-listing/data-row.hbs",
    "isStrictMode": false
  });

  let CongressListingDataRowComponent = (_dec = Ember._tracked, _dec2 = Ember._action, _dec3 = Ember._action, (_class = class CongressListingDataRowComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "isVisible", _descriptor, this);
    }

    get plannerHeight() {
      return Ember.String.htmlSafe(this.isVisible ? `height:${this.element.querySelector('.view-planners').scrollHeight}px` : 'height:0px');
    }

    expandRow() {
      this.isVisible = !this.isVisible;
    }

    setupElement(element) {
      this.element = element;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isVisible", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "expandRow", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "expandRow"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype)), _class));
  _exports.default = CongressListingDataRowComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CongressListingDataRowComponent);
});