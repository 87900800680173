define("congress-ap-client/components/annotation-table", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{#if this.isLoading}}
  	<TableLoader @loading={{this.isLoading}}/>
  {{else}}
  	<div class='session-footer-content'>
  		{{#if this.data.length}}
  			<ModelsTable
  				@data={{this.data}}
  				@columns={{this.columns}}
  				@expandedRowComponent={{component 'annotation-table/log-details' congress=@congress}}
  				@multipleColumnsSorting={{false}}
  				@pageSize={{100}}
  				@multipleExpand={{true}}
  				@useFilteringByColumns={{false}}
  				@showColumnsDropdown={{false}}
  				@showGlobalFilter={{false}}
  				@showPageSize={{false}}
  				@showComponentFooter={{false}}
  			/>
  		{{else}}
  			<div class="no-data-placeholder">
  				No annotation history
  			</div>
  		{{/if}}
  	</div>
  {{/if}}
  */
  {
    "id": "uwb8oF2T",
    "block": "[[[41,[30,0,[\"isLoading\"]],[[[1,\"\\t\"],[8,[39,1],null,[[\"@loading\"],[[30,0,[\"isLoading\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[10,0],[14,0,\"session-footer-content\"],[12],[1,\"\\n\"],[41,[30,0,[\"data\",\"length\"]],[[[1,\"\\t\\t\\t\"],[8,[39,2],null,[[\"@data\",\"@columns\",\"@expandedRowComponent\",\"@multipleColumnsSorting\",\"@pageSize\",\"@multipleExpand\",\"@useFilteringByColumns\",\"@showColumnsDropdown\",\"@showGlobalFilter\",\"@showPageSize\",\"@showComponentFooter\"],[[30,0,[\"data\"]],[30,0,[\"columns\"]],[50,\"annotation-table/log-details\",0,null,[[\"congress\"],[[30,1]]]],false,100,true,false,false,false,false,false]],null],[1,\"\\n\"]],[]],[[[1,\"\\t\\t\\t\"],[10,0],[14,0,\"no-data-placeholder\"],[12],[1,\"\\n\\t\\t\\t\\tNo annotation history\\n\\t\\t\\t\"],[13],[1,\"\\n\"]],[]]],[1,\"\\t\"],[13],[1,\"\\n\"]],[]]]],[\"@congress\"],false,[\"if\",\"table-loader\",\"models-table\",\"component\"]]",
    "moduleName": "congress-ap-client/components/annotation-table.hbs",
    "isStrictMode": false
  });

  let AnnotationTableComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._tracked, (_class = class AnnotationTableComponent extends _component.default {
    async fetchAnnotationData() {
      this.isLoading = true;

      try {
        this.data = await this.store.query('annotation', {
          congress: this.args.congress
        });
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.isLoading = false;
      }
    }

    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "store", _descriptor, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor2, this);

      _initializerDefineProperty(this, "data", _descriptor3, this);

      _initializerDefineProperty(this, "isLoading", _descriptor4, this);

      _defineProperty(this, "columns", [{
        propertyName: 'annotationName',
        title: 'Annotation Name',
        className: 'footer-table-columns annotation-name-col'
      }, {
        propertyName: 'email',
        title: 'Email',
        className: 'footer-table-columns email-col'
      }, {
        propertyName: 'startedOnFormatedDate',
        title: 'Started On',
        className: 'footer-table-columns started-on-col'
      }, {
        propertyName: 'endedByFormatedDate',
        title: 'Ended By',
        className: 'footer-table-columns ended-by-col'
      }, {
        component: 'annotation-table/status-col',
        title: 'Status',
        className: 'footer-table-columns status-col'
      }, {
        component: 'annotation-table/get-details',
        className: 'footer-table-columns get-details-col'
      }]);

      this.fetchAnnotationData();
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "data", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = AnnotationTableComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AnnotationTableComponent);
});