define("congress-ap-client/theme/custom", ["exports", "ember-models-table/themes/default"], function (_exports, _default) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CustomTheme extends _default.default {
    constructor(...args) {
      super(...args);

      _defineProperty(this, "rowSortingCellComponent", 'models-table/theme/row-sorting-cell');

      _defineProperty(this, "rowFilteringCellComponent", 'models-table/theme/row-filtering-cell');

      _defineProperty(this, "paginationNumericComponent", 'models-table/theme/pagination-numeric');

      _defineProperty(this, "summaryComponent", 'models-table/theme/summary');

      _defineProperty(this, "footerComponent", 'models-table/theme/footer');
    }

  }

  _exports.default = CustomTheme;
});