define("congress-ap-client/templates/tenants/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "B4Ke2I5Y",
    "block": "[[[10,0],[14,0,\"tenants-route\"],[12],[1,\"\\n    \"],[8,[39,0],null,null,null],[1,\"\\n\"],[13]],[],false,[\"tenant/tenant-list\"]]",
    "moduleName": "congress-ap-client/templates/tenants/index.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});