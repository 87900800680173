define("congress-ap-client/controllers/congress-view/create-congress", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class3, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let congressDetails = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, (_class = class congressDetails {
    constructor() {
      _initializerDefineProperty(this, "title", _descriptor, this);

      _initializerDefineProperty(this, "startDate", _descriptor2, this);

      _initializerDefineProperty(this, "endDate", _descriptor3, this);

      _initializerDefineProperty(this, "location", _descriptor4, this);

      _initializerDefineProperty(this, "website", _descriptor5, this);

      _initializerDefineProperty(this, "timezoneId", _descriptor6, this);
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "title", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "startDate", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "endDate", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "location", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "website", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "timezoneId", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  })), _class));
  const DEFAULT_TIMEZONE_ID = 'EST5EDT';
  let CongressViewCongressCreateController = (_dec7 = Ember.inject.service, _dec8 = Ember.inject.service, _dec9 = Ember.inject.service, _dec10 = Ember.inject.service, _dec11 = Ember.inject.service, _dec12 = Ember._tracked, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class3 = class CongressViewCongressCreateController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "store", _descriptor7, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor8, this);

      _initializerDefineProperty(this, "toast", _descriptor9, this);

      _initializerDefineProperty(this, "router", _descriptor10, this);

      _initializerDefineProperty(this, "timeZone", _descriptor11, this);

      _initializerDefineProperty(this, "isSaving", _descriptor12, this);

      _defineProperty(this, "newCongress", new congressDetails());
    }

    focus(element) {
      element.focus();
    }

    get hasDirtyAttributes() {
      return this.newCongress.title || this.newCongress.startDate || this.newCongress.endDate || this.newCongress.location || this.newCongress.website || this.newCongress.timezoneId;
    }

    get mandatoryAttributes() {
      return this.newCongress.title && this.newCongress.startDate && this.newCongress.endDate;
    }

    get timeZoneList() {
      return this.timeZone.listOfTimeZone;
    }

    clearValues() {
      this.newCongress.title = null;
      this.newCongress.startDate = null;
      this.newCongress.endDate = null;
      this.newCongress.location = null;
      this.newCongress.website = null;
      this.newCongress.timezoneId = null;
    }

    async saveCongress() {
      try {
        this.isSaving = true;
        let record = this.store.createRecord('congress', {
          name: this.newCongress.title,
          startDate: this.processedDate('startDate', this.newCongress.startDate),
          endDate: this.processedDate('endDate', this.newCongress.endDate),
          location: this.newCongress.location,
          website: this.newCongress.website,
          timezoneId: this.newCongress.timezoneId || DEFAULT_TIMEZONE_ID
        });
        let data = await record.save();
        this.clearValues();
        this.router.transitionTo('congress-view.congress-details', data);
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.isSaving = false;
      }
    }

    cancel() {
      this.newCongress = new congressDetails();
      this.router.transitionTo('congress-view');
    }

    processedDate(attribute, value) {
      let dateObj = (0, _momentTimezone.default)(value);

      if (attribute === 'startDate') {
        dateObj.set({
          hour: 0,
          minute: 0,
          second: 0
        });
      } else {
        dateObj.set({
          hour: 23,
          minute: 59,
          second: 59
        });
      }

      dateObj = dateObj.format('YYYY-MM-DDTHH:mm:ss');
      return _momentTimezone.default.tz(dateObj, this.newCongress.timezoneId ? this.newCongress.timezoneId : DEFAULT_TIMEZONE_ID).utc().format('YYYY-MM-DDTHH:mm:ss');
    }

  }, (_descriptor7 = _applyDecoratedDescriptor(_class3.prototype, "store", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class3.prototype, "errorHandler", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor9 = _applyDecoratedDescriptor(_class3.prototype, "toast", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor10 = _applyDecoratedDescriptor(_class3.prototype, "router", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor11 = _applyDecoratedDescriptor(_class3.prototype, "timeZone", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor12 = _applyDecoratedDescriptor(_class3.prototype, "isSaving", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class3.prototype, "clearValues", [_dec13], Object.getOwnPropertyDescriptor(_class3.prototype, "clearValues"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "saveCongress", [_dec14], Object.getOwnPropertyDescriptor(_class3.prototype, "saveCongress"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "cancel", [_dec15], Object.getOwnPropertyDescriptor(_class3.prototype, "cancel"), _class3.prototype), _applyDecoratedDescriptor(_class3.prototype, "processedDate", [_dec16], Object.getOwnPropertyDescriptor(_class3.prototype, "processedDate"), _class3.prototype)), _class3));
  _exports.default = CongressViewCongressCreateController;
});