define("congress-ap-client/components/congress-audit-table/log-details", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="log-details" style='height:{{this.expandedViewHeight}};' {{did-insert this.setupElement}}>
    <div class="log-details-wrapper">
      <div class="details-wrapper">
        <div>
          <span class="log log--label">Log ID</span>
          <span class="log log--detail">{{@record.id}}</span>
        </div>
        {{#if @record.transaction.details}}
          <div>
            <span class="log log--label">{{if (eq @record.transaction.outcome 'FAILURE') 'Failure '}} Details </span>
            <span class="log log--detail">{{this.stringifyDescription}}</span>
          </div>
        {{/if}}
      </div>
    </div>
  </div>
  
  */
  {
    "id": "Zx+fMBW4",
    "block": "[[[11,0],[24,0,\"log-details\"],[16,5,[29,[\"height:\",[30,0,[\"expandedViewHeight\"]],\";\"]]],[4,[38,0],[[30,0,[\"setupElement\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"log-details-wrapper\"],[12],[1,\"\\n    \"],[10,0],[14,0,\"details-wrapper\"],[12],[1,\"\\n      \"],[10,0],[12],[1,\"\\n        \"],[10,1],[14,0,\"log log--label\"],[12],[1,\"Log ID\"],[13],[1,\"\\n        \"],[10,1],[14,0,\"log log--detail\"],[12],[1,[30,1,[\"id\"]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,1,[\"transaction\",\"details\"]],[[[1,\"        \"],[10,0],[12],[1,\"\\n          \"],[10,1],[14,0,\"log log--label\"],[12],[1,[52,[28,[37,2],[[30,1,[\"transaction\",\"outcome\"]],\"FAILURE\"],null],\"Failure \"]],[1,\" Details \"],[13],[1,\"\\n          \"],[10,1],[14,0,\"log log--detail\"],[12],[1,[30,0,[\"stringifyDescription\"]]],[13],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@record\"],false,[\"did-insert\",\"if\",\"eq\"]]",
    "moduleName": "congress-ap-client/components/congress-audit-table/log-details.hbs",
    "isStrictMode": false
  });

  let CongressAuditTableLogDetailsComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember._tracked, _dec4 = Ember._action, (_class = class CongressAuditTableLogDetailsComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "ajax", _descriptor, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor2, this);

      _initializerDefineProperty(this, "element", _descriptor3, this);
    }

    get expandedViewHeight() {
      return this.element && this.args.record.isExpanded ? `${this.element.scrollHeight}px` : '0px';
    }

    get stringifyDescription() {
      return JSON.stringify(this.args.record.transaction.details);
    }

    setupElement(element) {
      this.args.record.expandedView = element;
      this.element = element;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "element", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setupElement", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "setupElement"), _class.prototype)), _class));
  _exports.default = CongressAuditTableLogDetailsComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, CongressAuditTableLogDetailsComponent);
});