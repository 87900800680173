define("congress-ap-client/utils/http-url-to-web-socket-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = httpUrlToWebSocketUrl;

  /**
   * Converts an HTTP(S) url to a WS(S) URL
   * Example:
   *     httpUrlToWebSockeUrl("http://app.zoomrx.com/") -> ws://app.zoomrx.com/
   *     httpUrlToWebSockeUrl("https://app.zoomrx.com/") -> wss://app.zoomrx.com/
   *
   * @param {string} url
   * @return {string}
   */
  function httpUrlToWebSocketUrl(url) {
    return url.replace(/(http)(s)?\:\/\//, 'ws$2://');
  }
});