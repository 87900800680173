define("congress-ap-client/helpers/date-from-now", ["exports", "ember-date-fns/helpers/date-from-now"], function (_exports, _dateFromNow) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _dateFromNow.default;
    }
  });
  Object.defineProperty(_exports, "dateFromNow", {
    enumerable: true,
    get: function () {
      return _dateFromNow.dateFromNow;
    }
  });
});