define("congress-ap-client/transforms/date", ["exports", "@ember-data/serializer/transform", "moment"], function (_exports, _transform, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DateTransform extends _transform.default {
    deserialize(serialized) {
      return serialized;
    }

    serialize(deserialized) {
      return (0, _moment.default)(deserialized).format('YYYY-MM-DD');
    }

  }

  _exports.default = DateTransform;
});