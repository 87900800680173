define("congress-ap-client/components/app-header", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='app-header' ...attributes>
      <div class='greeting'>Greetings, {{this.firstName}}</div>
      <div class='header-right-panel'>
          {{!-- <SearchBox @searchPlaceHolder="Search" @onSearch={{this.searchServer}} @timeDelay={{2000}}/> --}}
          {{!-- TODO: Generic component for popup --}}
          <div class='dropdown'>
              <div class='name-icon'>{{this.iconName}}</div>
              <div class='dropdown-content'>
                  <div class="dropdown-up-arrow"/>
                  <div class='logout-button' {{on 'click' this.invalidateSession}}>Logout</div>
              </div>
          </div>
      </div>
  </div>
  */
  {
    "id": "xYT3wHxl",
    "block": "[[[11,0],[24,0,\"app-header\"],[17,1],[12],[1,\"\\n    \"],[10,0],[14,0,\"greeting\"],[12],[1,\"Greetings, \"],[1,[30,0,[\"firstName\"]]],[13],[1,\"\\n    \"],[10,0],[14,0,\"header-right-panel\"],[12],[1,\"\\n\"],[1,\"        \"],[10,0],[14,0,\"dropdown\"],[12],[1,\"\\n            \"],[10,0],[14,0,\"name-icon\"],[12],[1,[30,0,[\"iconName\"]]],[13],[1,\"\\n            \"],[10,0],[14,0,\"dropdown-content\"],[12],[1,\"\\n                \"],[10,0],[14,0,\"dropdown-up-arrow\"],[12],[13],[1,\"\\n                \"],[11,0],[24,0,\"logout-button\"],[4,[38,0],[\"click\",[30,0,[\"invalidateSession\"]]],null],[12],[1,\"Logout\"],[13],[1,\"\\n            \"],[13],[1,\"\\n        \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13]],[\"&attrs\"],false,[\"on\"]]",
    "moduleName": "congress-ap-client/components/app-header.hbs",
    "isStrictMode": false
  });

  let AppHeaderComponent = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, _dec3 = Ember.inject.service, _dec4 = Ember._action, (_class = class AppHeaderComponent extends _component.default {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "session", _descriptor, this);

      _initializerDefineProperty(this, "errorHandler", _descriptor2, this);

      _initializerDefineProperty(this, "spinner", _descriptor3, this);
    }

    get firstName() {
      return this.session.data.firstName;
    }

    get lastName() {
      return this.session.data.lastName;
    }

    get iconName() {
      return this.firstName[0] + this.lastName[0];
    }

    async invalidateSession() {
      try {
        this.spinner.show();
        await this.session.invalidate();
      } catch (error) {
        this.errorHandler.notifyError(error);
      } finally {
        this.spinner.hide();
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "errorHandler", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "spinner", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "invalidateSession", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "invalidateSession"), _class.prototype)), _class));
  _exports.default = AppHeaderComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, AppHeaderComponent);
});