define("congress-ap-client/utils/custom-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  class CustomEvent {
    constructor() {
      _defineProperty(this, "eventCallBacks", {});
    }

    on(event, callBack) {
      if (!event) {
        (true && !(false) && Ember.assert('on called without valid event name', false));
        return;
      }

      if (!callBack || typeof callBack !== 'function') {
        (true && !(false) && Ember.assert(`on expects function as last argument, you passed ${typeof callBack}`, false));
        return;
      }

      if (!this.eventCallBacks[event]) {
        this.eventCallBacks[event] = [];
      }

      if (this.eventCallBacks[event].indexOf(callBack) === -1) {
        this.eventCallBacks[event].push(callBack);
      }
    }

    trigger(event, ...args) {
      var _this$eventCallBacks$;

      if (!event) {
        (true && !(false) && Ember.assert('trigger called without valid event name', false));
        return;
      }

      (_this$eventCallBacks$ = this.eventCallBacks[event]) === null || _this$eventCallBacks$ === void 0 ? void 0 : _this$eventCallBacks$.forEach(callBack => callBack(...args));
    }

    off(event, callBack) {
      var _this$eventCallBacks$2;

      if (!event) {
        (true && !(false) && Ember.assert('off called without valid event name', false));
        return;
      }

      if (!callBack || typeof callBack !== 'function') {
        (true && !(false) && Ember.assert(`off expects function as last argument; you passed ${typeof callBack}`, false));
        return;
      }

      let indexOfCallBack = ((_this$eventCallBacks$2 = this.eventCallBacks[event]) === null || _this$eventCallBacks$2 === void 0 ? void 0 : _this$eventCallBacks$2.indexOf(callBack)) ?? -1;

      if (indexOfCallBack !== -1) {
        this.eventCallBacks[event].splice(indexOfCallBack, 1);
        !this.eventCallBacks[event].length && delete this.eventCallBacks[event];
        return;
      }

      (true && !(false) && Ember.assert('Attempted to remove a listener which did not exist, you may have attempted to remove it before it was added', false));
    }

  }

  _exports.default = CustomEvent;
});