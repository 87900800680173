define("congress-ap-client/services/retain-filters", ["exports", "moment-timezone", "congress-ap-client/utils/is-empty"], function (_exports, _momentTimezone, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let RetainFiltersService = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, (_class = class RetainFiltersService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _initializerDefineProperty(this, "filters", _descriptor2, this);

      _initializerDefineProperty(this, "sorts", _descriptor3, this);
    }

    setFilter(tableId, key, value) {
      let keyName = `${this.router.currentURL}:${tableId}:${key}`;

      if (value) {
        this.filters = { ...this.filters,
          [keyName]: value
        };
      } else {
        delete this.filters[keyName];
      }
    }

    setSort(tableId, key, value) {
      let keyName = `${this.router.currentURL}:${tableId}:${key}`;

      if (Ember.isBlank(value)) {
        delete this.sorts[keyName];
      } else {
        this.sorts[keyName] = value;
      }
    }

    getFilterFor(tableId, key) {
      return this.filters[`${this.router.currentURL}:${tableId}:${key}`];
    }

    getSortFor(tableId, key) {
      return this.sorts[`${this.router.currentURL}:${tableId}:${key}`];
    }

    getAllFiltersFor(tableId) {
      let filtersForTable = {};
      let keyNameStartsWith = `${this.router.currentURL}:${tableId}:`;

      for (let column in this.filters) {
        if (column.startsWith(keyNameStartsWith)) {
          filtersForTable[column.slice(keyNameStartsWith.length)] = this.filters[column];
        }
      }

      return filtersForTable;
    }

    getAllSortsFor(tableId) {
      let sortsForTable = {};
      let keyNameStartsWith = `${this.router.currentURL}:${tableId}:`;

      for (let column in this.sorts) {
        if (column.startsWith(keyNameStartsWith)) {
          sortsForTable[column.slice(keyNameStartsWith.length)] = this.sorts[column];
        }
      }

      return sortsForTable;
    }

    modifyDateFiltersFor(url, key, oldTimezoneId, newTimezoneId) {
      let keyName = `${url}:${key}`;
      let filterString = this.filters[keyName];

      if (!(0, _isEmpty.default)(filterString)) {
        let dateString = _momentTimezone.default.utc(filterString).tz(oldTimezoneId).format('YYYY-MM-DDTHH:mm:ss');

        filterString = _momentTimezone.default.tz(dateString, newTimezoneId).utc().format('YYYY-MM-DDTHH:mm:ss');
        this.filters = { ...this.filters,
          [keyName]: filterString
        };
      }

      return;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "filters", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "sorts", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {};
    }
  })), _class));
  _exports.default = RetainFiltersService;
});