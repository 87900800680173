define("congress-ap-client/serializers/application", ["exports", "@ember-data/serializer/json"], function (_exports, _json) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class ApplicationSerializer extends _json.default {
    // formats response for ember-data request to convert payload into JSON:API Spec Doc
    normalizeResponse(store, primaryModelClass, payload = {}, id, requestType) {
      let normalizedPayload;
      normalizedPayload = super.normalizeResponse(store, primaryModelClass, payload.data ? payload.data : [], id, requestType);

      if (payload.meta) {
        normalizedPayload.meta = this.extractMeta(store, primaryModelClass, payload);
        normalizedPayload.meta.pagesCount = Math.ceil(normalizedPayload.meta.total / normalizedPayload.meta.limit);
      }

      return normalizedPayload;
    }

  }

  _exports.default = ApplicationSerializer;
});