define("congress-ap-client/components/button", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <button class='standard-button {{if this.loading 'loading'}}' disabled={{this.disabled}} ...attributes>
      {{#if this.loading}}
          {{#if (has-block 'loader')}}
              {{yield to='loader'}}
          {{else}}
              <div class='standard-button-spinner'></div>
          {{/if}}
      {{else}}
          {{yield}}
      {{/if}}
  </button>
  */
  {
    "id": "NhReMhaP",
    "block": "[[[11,\"button\"],[16,0,[29,[\"standard-button \",[52,[30,0,[\"loading\"]],\"loading\"]]]],[16,\"disabled\",[30,0,[\"disabled\"]]],[17,1],[12],[1,\"\\n\"],[41,[30,0,[\"loading\"]],[[[41,[48,[30,2]],[[[1,\"            \"],[18,2,null],[1,\"\\n\"]],[]],[[[1,\"            \"],[10,0],[14,0,\"standard-button-spinner\"],[12],[13],[1,\"\\n\"]],[]]]],[]],[[[1,\"        \"],[18,3,null],[1,\"\\n\"]],[]]],[13]],[\"&attrs\",\"&loader\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "congress-ap-client/components/button.hbs",
    "isStrictMode": false
  });

  class ButtonComponent extends _component.default {
    get loading() {
      return this.args.loading ?? false;
    }

    get disabled() {
      return this.loading || this.args.disabled;
    }

  }
  /*
  
  1. Button with default loader
  
      <Button
          class="primary"
          @loading={{this.isLoading}}
          {{action this.submit}}>
              Submit
      </Button>
  
  2. Button with custom loader
  
      <Button
          class="primary"
          @loading={{this.isLoading}}
          {{action this.submit}}>
              <:loader>
                  Submitting...
              </:loader>
              <:default>
                  Submit
              </:default>
      </Button>
  
  3. Supported class names primary, secondary, tertiary, disabled, cautious, cautious
  
  */


  _exports.default = ButtonComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ButtonComponent);
});