define("congress-ap-client/services/error-handler", ["exports", "congress-ap-client/utils/is-empty"], function (_exports, _isEmpty) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let ErrorHandlerService = (_dec = Ember.inject.service, _dec2 = Ember.inject.service, (_class = class ErrorHandlerService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "toast", _descriptor, this);

      _initializerDefineProperty(this, "session", _descriptor2, this);
    }

    notifyError(error, options = {}) {
      var _error;

      if (!error) {
        (true && !(false) && Ember.assert('No (valid) error object is provided!', false));
        return;
      }

      if (typeof error === 'string' && error.charAt(0) === '{') {
        error = JSON.parse(error);
      }

      if (typeof error === 'string' && !(0, _isEmpty.default)(error)) {
        this.toast.error(error);
        return;
      }

      if ((_error = error) !== null && _error !== void 0 && _error.errors) {
        if (Array.isArray(error.errors) && error.errors.length) {
          this.handleErrorMessage(error.errors[0], options);
          return;
        }

        if (typeof error.errors === 'object') {
          this.handleErrorMessage(error.errors, options);
          return;
        }
      }

      console.error(error);
    }

    handleErrorMessage(error, options) {
      // Show alert with close button for 4xx error code messages
      if (/^[4][0-9][0-9]$/.test(+error.status) && error.status !== 401 && error.status !== 403 || error.status === undefined && error.detail) {
        options = { ...options,
          showCloseButton: true,
          timer: 0
        };
      }

      switch (+error.status) {
        case 500:
        case 502:
        case 504:
          this.toast.error('Oops! Something went wrong. Please try again.');
          return;

        case 401:
          this.session.invalidate();
          break;

        case 404:
          if (error.title && (error.title.indexOf('<html>') !== -1 || error.title.indexOf('</html>') !== -1)) {
            this.toast.error('Oops! Something went wrong. Please try again later.');
            return;
          }

          break;
      }

      error.title && this.toast.error(error.title + (error.detail ? ' : ' + (Array.isArray(error.detail) ? error.detail.join(', ') : error.detail) : ''), options);
      return;
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "toast", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "session", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = ErrorHandlerService;
});